import React from "react";
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  down_sort_arrow_icon,
  edit_active_icon,
  edit_disabled_icon,
  reopen_disabled_icon,
  reopen_icon,
  review_disabled_icon,
  review_icon,
  up_sort_arrow_icon,
} from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import TablePagination from "../TablePagination/TablePagination";
import { evaluationStatusChip, font_13_icon, font_9 } from "../../utils/utils";
import { sortInAscending, sortInDescending } from "../../constants/constants";
import dayjs from "dayjs";

const EvaluationListingTable = ({
  evaluationListing,
  totalPages,
  handlePageChange,
  currentPage,
  handleSorting,
  sortBy,
  orderBy,
  totalResult,
  handleEditEvaluation,
  handleViewEvaluation,
  handleReopenEvaluationModal,
  isTypeInterview,
}) => {
  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead className="table-head-container">
            <TableRow className="table-head-row" sx={{ height: "2.711rem" }}>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Attendee Name/Email</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "attendeeName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "attendeeName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "attendeeName")}
                      disabled={sortBy === "attendeeName" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    {(sortBy !== "attendeeName" || orderBy !== "DESC") && (
                      <IconButtons
                        height={
                          sortBy === "attendeeName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "attendeeName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("DESC", "attendeeName")
                        }
                        disabled={
                          sortBy === "attendeeName" && orderBy === "DESC"
                        }
                        tooltip={sortInDescending}
                      />
                    )}
                  </Box>
                </Typography>
              </TableCell>
              {isTypeInterview && (
                <TableCell
                  align="left"
                  sx={{ minWidth: "8rem" }}
                  className="padding-left-1"
                >
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">
                      Interviewers(Lead, Co)
                    </Box>
                  </Typography>
                </TableCell>
              )}
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Marks</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "marksObtained" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "marksObtained" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "marksObtained")}
                      disabled={sortBy === "marksObtained" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "marksObtained" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "marksObtained" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "marksObtained")}
                      disabled={
                        sortBy === "marksObtained" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>

              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Status</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "status" && orderBy === "ASC" ? null : font_9
                      }
                      width={
                        sortBy === "status" && orderBy === "ASC" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "status")}
                      disabled={sortBy === "status" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "status" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "status" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "status")}
                      disabled={sortBy === "status" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Submission Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "endDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "endDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "endDate")}
                      disabled={sortBy === "endDate" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "endDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "endDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "endDate")}
                      disabled={sortBy === "endDate" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "9rem", maxWidth: "9rem", width: "9rem" }}
                className="padding-left-1 padding-right-1"
              >
                <Typography variant="font_13_bold">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationListing?.map((evaluation, index) => {
              const evaluationId = evaluation?.id;
              const leadInterviewer = evaluation?.leadInterviewer?.fullName;
              const coInterviewer = evaluation?.coInterviewer?.fullName;
              const marksObtained = evaluation?.marksObtained;
              const totalMarks = evaluation?.totalMarks;
              const submissionDate = evaluation?.endDate;
              const attendee =
                evaluation?.bootcampAttendee?.attendee ||
                evaluation?.moduleAttendee?.attendee?.attendee;
              const status = evaluationStatusChip(evaluation?.status);
              const permissions = evaluation?.permission;
              const {
                canViewEditAssessmentEvaluation,
                canEditAssessmentEvaluation,
                editEvaluationMessage,
              } = permissions || {};

              return (
                <TableRow className="table-body-row" hover key={index}>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {attendee?.fullName ? `${attendee?.fullName}` : "-"}
                    </Typography>
                  </TableCell>
                  {isTypeInterview && (
                    <TableCell align="left" className="padding-left-1">
                      <Typography variant="font_12">
                        {leadInterviewer ? (
                          <>
                            {leadInterviewer}
                            <br />
                          </>
                        ) : (
                          <>
                            {"-"}
                            <br />
                          </>
                        )}
                        {coInterviewer ? coInterviewer : "-"}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="left" className="padding-left-1">
                    <Typography
                      variant="font_12"
                      color={marksObtained ? "secondary" : null}
                    >
                      {marksObtained ? (
                        <>
                          {marksObtained}/{totalMarks}
                        </>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      <Chip
                        className="same-size-chip"
                        label={status?.name}
                        variant={status?.theme}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {submissionDate
                        ? dayjs(submissionDate)?.format("DD/MM/YYYY hh:mm A")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell className="padding-left-1 padding-right-1">
                    <Box>
                      <IconButtons
                        height={font_13_icon}
                        width={font_13_icon}
                        image={review_icon}
                        classList="margin-right-1"
                        handleClick={() => handleViewEvaluation(evaluationId)}
                        tooltip="View"
                        disabledImage={review_disabled_icon}
                      />
                      {isTypeInterview && canViewEditAssessmentEvaluation && (
                        <IconButtons
                          tooltip="Edit Evaluation"
                          width={font_13_icon}
                          height={font_13_icon}
                          image={edit_active_icon}
                          handleClick={(event) =>
                            handleEditEvaluation(event, evaluationId)
                          }
                          classList="margin-right-1"
                          disabled={!canEditAssessmentEvaluation}
                          disabledImage={edit_disabled_icon}
                          disabledTooltip={
                            !canEditAssessmentEvaluation
                              ? editEvaluationMessage
                              : null
                          }
                        />
                      )}
                      <IconButtons
                        height={font_13_icon}
                        width={font_13_icon}
                        image={reopen_icon}
                        classList="margin-right-1"
                        handleClick={() =>
                          handleReopenEvaluationModal(evaluationId)
                        }
                        tooltip="Reopen Evaluation"
                        disabledImage={reopen_disabled_icon}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalResult} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default EvaluationListingTable;
