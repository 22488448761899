import axios from "axios";
import Cookies from "universal-cookie";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import CustomToaster from "../components/Toaster";
import { toast } from "react-hot-toast";
import { getNavigate } from "./utils";

const { authCookieName, backendBaseUrl } = require("../config/config");
const cookies = new Cookies();

const ApiService = (apiConfig, addAuthToken = true, fileUpload = false) => {
  const navigate = getNavigate();
  if (addAuthToken) {
    apiConfig.headers = {
      Authorization: `Bearer ${cookies.get(authCookieName)}`,
    };
  }

  if (fileUpload) {
    const formData = new FormData();
    formData.append(
      "json",
      new Blob([JSON.stringify(apiConfig.data.json)], {
        type: "application/json",
      })
    );

    if (apiConfig.data.files && apiConfig.data.files.length > 0) {
      apiConfig.data.files.forEach((file) => {
        formData.append("files", file);
      });
    }

    apiConfig.data = formData;
    apiConfig.headers = {
      ...apiConfig.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  apiConfig.url = `${backendBaseUrl}${apiConfig.url}`;
  return axios(apiConfig)
    .then(function (_response) {
      return new Promise((resolve) => {
        return resolve(_response.data);
      });
    })
    .catch((err) => {
      const error = err?.response?.data?.errors;
      const currentPath = window.location.pathname;
      if (err?.response?.status === 404) {
        navigate("/error", {
          replace: true,
          state: { isAccessDenied: false },
        });
        return;
      } else if (err?.response?.status === 403) {
        navigate("/error", {
          replace: true,
          state: { isAccessDenied: true },
        });
        return;
      }
      // Check if the current URL contains '/error'
      if (!currentPath.includes("/error")) {
        error.map((e) => {
          toast.custom(
            <CustomToaster
              message={e}
              title={TOAST_TITLE.ERROR}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.ERROR}
            />
          );
        });
      }
      throw err.response.data.errors;
    });
};

export default ApiService;
