import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Link, useLocation } from "react-router-dom";
import { getYear } from "../../utils/utils";
import { SidebarList } from "./sidebarUtils";
import { ttn_logo } from "../../constants/icons";
import { useSelector } from "react-redux";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const Sidebar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();

  const matchPath = (selectedPath, location) =>
    location.pathname === `${selectedPath}`;
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;

  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(location.pathname);

  useEffect(() => {
    if (
      location?.pathname === "/exercises/reviewer" ||
      location.pathname === "/exercises/attendee"
    ) {
      setOpenSubMenuIndex("/exercise");
    } else if (
      location?.pathname === "/performance/reviewer" ||
      location.pathname === "/performance/attendee"
    ) {
      setOpenSubMenuIndex("/performance");
    }

    if (location.pathname !== selectedMenuItem) {
      setSelectedMenuItem(location.pathname);
    }
  }, [location.pathname]);

  const handleMenuItemClick = (linkTo) => {
    setSelectedMenuItem(linkTo);
    setOpenSubMenuIndex(openSubMenuIndex === linkTo ? null : linkTo);
  };

  const handleToggleSidebar = () => setCollapsed(!collapsed);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: collapsed ? "center" : "flex-end",
          padding: "0.1rem",
        }}
      >
        <IconButton
          onClick={handleToggleSidebar}
          color="offline"
          disableRipple={false}
        >
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          minWidth: collapsed ? "" : "12.375rem",
          maxWidth: collapsed ? "5rem" : "",
        }}
        className={`sidebar-container ${collapsed ? "collapsed" : ""}`}
      >
        <List>
          {SidebarList(roles, security?.loggedInUser).map(
            (
              { defaultIcon, hoverIcon, name, linkTo, isExpandable, subMenus },
              index
            ) =>
              !(subMenus && subMenus?.length === 0) && (
                <ListItem
                  disablePadding
                  sx={{ marginBottom: "0.8rem", flexDirection: "column" }}
                  key={index}
                >
                  <Link
                    to={!isExpandable && linkTo}
                    className="sidebar-link-tag"
                    key={index}
                  >
                    <ListItemButton
                      sx={{
                        "& .sidebar-link-icon": {
                          backgroundImage:
                            selectedMenuItem === linkTo ||
                            (isExpandable && location.pathname.includes(linkTo))
                              ? `url(${hoverIcon})`
                              : `url(${defaultIcon})`,
                        },
                        ":hover": {
                          "& .sidebar-link-icon": {
                            backgroundImage: `url(${hoverIcon})`,
                          },
                        },
                      }}
                      className={`sidebar-list-item-btn ${
                        selectedMenuItem === linkTo ||
                        (isExpandable && location.pathname.includes(linkTo))
                          ? "sidebar-list-item-active-btn"
                          : ""
                      }`}
                      onClick={() => handleMenuItemClick(linkTo)}
                    >
                      <ListItemIcon
                        className="sidebar-link-icon-container"
                        button
                      >
                        <Box className="sidebar-link-icon"></Box>
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-link-text text-bold"
                        primary={name}
                      />
                    </ListItemButton>
                  </Link>
                  {subMenus?.length > 0 && (
                    <>
                      <Collapse
                        in={openSubMenuIndex === linkTo}
                        timeout={300}
                        sx={{
                          width: "100%",
                          transition: "5.5s ease-in-out",
                        }}
                        unmountOnExit
                      >
                        <List>
                          {subMenus.map(({ name, linkTo }, subIndex) => (
                            <ListItem
                              disablePadding
                              className="sub-menu-item"
                              key={subIndex}
                            >
                              <Link to={linkTo} className="sidebar-link-tag">
                                <ListItemButton
                                  sx={{
                                    padding: `${
                                      collapsed ? "0" : "0.5rem 1rem !important"
                                    }`,
                                  }}
                                  className={
                                    matchPath(linkTo, location)
                                      ? "sidebar-list-item-active-btn"
                                      : "sidebar-list-item-btn"
                                  }
                                >
                                  <ListItemText
                                    className="sidebar-link-text text-bold"
                                    primary={name}
                                  />
                                </ListItemButton>
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  )}
                </ListItem>
              )
          )}
        </List>
        <Box className="sidebar-footer">
          <Box
            className="sidebar-footer-logo"
            sx={{ backgroundImage: `url(${ttn_logo})` }}
          ></Box>
          <Typography className="sidebar-footer-text">
            TO THE NEW © {getYear()}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
