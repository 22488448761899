import React from "react";
import IconButtons from "../../../components/IconButtons/IconButtons";
import {
  chat_active_icon,
  edit_active_icon,
  edit_disabled_icon,
  edit_reviewer_disabled_icon,
  edit_reviewer_icon,
  file_upload_disabled_icon,
  file_upload_icon,
  reopen_disabled_icon,
  reopen_icon,
  review_disabled_icon,
  review_icon,
} from "../../../constants/icons";
import { exerciseStatusChip, font_13_icon } from "../../../utils/utils";
import Constants, { HelpTexts } from "../../../constants/constants";
import { useSelector } from "react-redux";

const { IN_REVIEW, REOPENED, PENDING, REVIEWED } = Constants;
const { ATTENDEE_DROPPED, ATTENDEE_SEPARATED } = HelpTexts;

const {
  ASSIGNED_REVIEWER_CAN_REOPEN,
  ASSIGNED_REVIEWER_CAN_EDIT_SCORE,
  ASSIGNED_REVIEWER_CAN_REVIEW,
} = HelpTexts;

const ExerciseStatusActions = ({
  handleCommentOpen,
  attendeeDeleted,
  handleEditExerciseTabOpen,
  exercise,
  handleSubmitOpen,
  permissions,
  exerciseStatus,
  submitReviewOpen,
  isBootcampCompleted,
  handleReopenModalOpen,
  handleEditReviewModalOpen,
  userDropped,
  userSeperated,
}) => {
  const security = useSelector((state) => state?.security);
  const userId = security?.loggedInUser?.id;
  const isMentor =
    permissions?.mentor &&
    (exercise?.attendee?.leadMentor?.id === userId ||
      exercise?.attendee?.coMentor?.id === userId ||
      exercise?.attendee?.attendee?.leadMentor?.id === userId ||
      exercise?.attendee?.attendee?.coMentor?.id === userId);
  const isReviewer =
    permissions?.exerciseReviewer && exercise?.reviewer?.id === userId;
  const disableExerciseReviewActions =
    isMentor && exercise?.reviewer && exercise?.reviewer?.id !== userId;
  const isAddCommentAllowed =
    !attendeeDeleted &&
    !userSeperated &&
    !userDropped &&
    (permissions?.spoc || permissions?.attendee || isMentor || isReviewer);
  const exerciseState = exerciseStatusChip(exerciseStatus)?.name;
  return (
    <>
      <IconButtons
        height={font_13_icon}
        width={font_13_icon}
        image={chat_active_icon}
        classList="margin-right-1"
        handleClick={(event) =>
          handleCommentOpen(event, exercise?.id, isAddCommentAllowed)
        }
        tooltip="Show Comments"
      />
      {permissions?.attendee && (
        <IconButtons
          height={font_13_icon}
          width={font_13_icon}
          image={file_upload_icon}
          classList="margin-right-1"
          handleClick={(event) => handleSubmitOpen(event, exercise?.id)}
          tooltip="Submit Exercise"
          disabledImage={file_upload_disabled_icon}
          disabled={
            isBootcampCompleted ||
            (exerciseStatus !== PENDING && exerciseStatus !== REOPENED)
          }
          disabledTooltip={
            isBootcampCompleted
              ? "Bootcamp is completed"
              : exerciseStatus !== PENDING && exerciseStatus !== REOPENED
              ? `Exercise cannot be submitted in ${exerciseState.toLowerCase()} state`
              : null
          }
        />
      )}
      {(permissions?.spoc || isMentor) && (
        <IconButtons
          height={font_13_icon}
          width={font_13_icon}
          image={edit_reviewer_icon}
          tooltip="Edit Reviewer"
          classList="margin-right-1"
          disabledImage={edit_reviewer_disabled_icon}
          disabled={
            userSeperated ||
            userDropped ||
            attendeeDeleted ||
            isBootcampCompleted ||
            exerciseStatus === REVIEWED
          }
          handleClick={() =>
            handleEditExerciseTabOpen(exercise?.id, exercise?.reviewer)
          }
          disabledTooltip={
            userSeperated
              ? ATTENDEE_SEPARATED
              : userDropped
              ? ATTENDEE_DROPPED
              : attendeeDeleted
              ? "Attendee has been deleted"
              : isBootcampCompleted
              ? "Bootcamp is completed"
              : exerciseStatus === REVIEWED
              ? "Cannot change the reviewer as this is reviewed"
              : null
          }
        />
      )}
      {(isReviewer || isMentor) && (
        <>
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={edit_active_icon}
            classList="margin-right-1"
            handleClick={() => handleEditReviewModalOpen(exercise?.id)}
            tooltip="Edit Exercise Score"
            disabledImage={edit_disabled_icon}
            disabled={
              userSeperated ||
              userDropped ||
              attendeeDeleted ||
              disableExerciseReviewActions ||
              isBootcampCompleted ||
              !(exerciseStatus === REVIEWED)
            }
            disabledTooltip={
              userSeperated
                ? ATTENDEE_SEPARATED
                : userDropped
                ? ATTENDEE_DROPPED
                : attendeeDeleted
                ? "Attendee has been deleted"
                : isBootcampCompleted
                ? "Bootcamp is completed"
                : disableExerciseReviewActions
                ? ASSIGNED_REVIEWER_CAN_EDIT_SCORE
                : !(exerciseStatus === REVIEWED)
                ? `Score cannot be edited in ${exerciseState.toLowerCase()} state`
                : null
            }
          />
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={review_icon}
            classList="margin-right-1"
            handleClick={() => submitReviewOpen(exercise?.id)}
            tooltip="Review Exercise"
            disabledImage={review_disabled_icon}
            disabled={
              userSeperated ||
              userDropped ||
              attendeeDeleted ||
              disableExerciseReviewActions ||
              isBootcampCompleted ||
              !(exerciseStatus === IN_REVIEW)
            }
            disabledTooltip={
              userSeperated
                ? ATTENDEE_SEPARATED
                : userDropped
                ? ATTENDEE_DROPPED
                : attendeeDeleted
                ? "Attendee has been deleted"
                : isBootcampCompleted
                ? "Bootcamp is completed"
                : disableExerciseReviewActions
                ? ASSIGNED_REVIEWER_CAN_REVIEW
                : !(exerciseStatus === IN_REVIEW)
                ? `Review is not allowed in ${exerciseState.toLowerCase()} state`
                : null
            }
          />
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={reopen_icon}
            classList="margin-right-1"
            handleClick={() => handleReopenModalOpen(exercise?.id)}
            tooltip="Reopen Exercise"
            disabledImage={reopen_disabled_icon}
            disabled={
              userSeperated ||
              userDropped ||
              attendeeDeleted ||
              disableExerciseReviewActions ||
              isBootcampCompleted ||
              !(exerciseStatus === REVIEWED || exerciseStatus === IN_REVIEW)
            }
            disabledTooltip={
              userSeperated
                ? ATTENDEE_SEPARATED
                : userDropped
                ? ATTENDEE_DROPPED
                : attendeeDeleted
                ? "Attendee has been deleted"
                : isBootcampCompleted
                ? "Bootcamp is completed"
                : disableExerciseReviewActions
                ? ASSIGNED_REVIEWER_CAN_REOPEN
                : !(exerciseStatus === REVIEWED || exerciseStatus === IN_REVIEW)
                ? `Reopening is not allowed in ${exerciseState.toLowerCase()} state`
                : null
            }
          />
        </>
      )}
    </>
  );
};

export default ExerciseStatusActions;
