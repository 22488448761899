import React, { useEffect, useState } from "react";
import "./performance.scss";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useParams } from "react-router-dom/dist";
import PerformanceCard from "../../components/PerformanceCard/PerformanceCard";
import {
  clearPerformanceData,
  getPerformanceData,
} from "../../store/performance";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";

const Performance = () => {
  const params = useParams();
  const asAttendee = params?.user === "attendee";
  const dispatch = useDispatch();
  const security = useSelector((state) => state?.security);
  const performanceData = useSelector((state) => state?.performance);
  const performanceList = performanceData?.performanceData;
  const performanceDataLoader = performanceData?.performanceDataLoading;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getPerformanceData({ asAttendee }));
      setIsLoading(false);
    };

    if (security?.loggedInUser?.id) {
      fetchData();
    }

    return () => {
      dispatch(clearPerformanceData());
    };
  }, [asAttendee]);

  //-------------------------------------------------------------------

  const Row = ({ row, index }) => {
    return (
      <>
        <TableRow className="performance-table-body-row" key={index}>
          <TableCell
            className="performance-table-body-column"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: 0,
            }}
            colSpan={!asAttendee ? 9 : 8}
          >
            <PerformanceCard
              performance={row}
              id={row?.id}
              asAttendee={asAttendee}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };

  //------------------------------ Breadcrumbs ------------------------------//

  const breadcrumbs = [
    {
      label: "Exercises",
    },
    { label: asAttendee ? "My Performance" : "Mentee's Performance" },
  ];

  if (isLoading || performanceDataLoader) return <Loader />;

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Performance</Typography>
        </Grid>
        <Grid item md={12} className="body-container">
          <Paper
            elevation={0}
            container
            className="performance-parent-container"
          >
            {!performanceDataLoader &&
            !performanceList?.data?.results?.length ? (
              <Grid container className="no-data-container">
                <NoDataAvailable
                  imgHeight={4}
                  imgWidth={4.5}
                  message="No Data Available"
                />
              </Grid>
            ) : (
              <TableContainer className="performance-table-container">
                <Table stickyHeader>
                  <TableHead className="table-head-container">
                    <TableRow className="performance-table-head-row">
                      {!asAttendee && (
                        <TableCell
                          className="padding-left-1"
                          sx={{ minWidth: "14rem" }}
                        >
                          <Typography variant="font_13_bold">
                            Attendee
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "18rem", maxWidth: "18rem" }}
                      >
                        <Typography variant="font_13_bold">Bootcamp</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: "21rem" }}
                        className="padding-left-1"
                      >
                        <Typography variant="font_13_bold">Module</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: "13.625rem" }}
                        className="padding-left-1"
                      >
                        <Typography variant="font_13_bold">
                          Lead Mentor
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: "13.625rem" }}
                        className="padding-left-1"
                      >
                        <Typography variant="font_13_bold">
                          Co Mentor
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: "10rem" }}>
                        <Box className="double-head-table">
                          <Typography
                            variant="font_11_bold_700"
                            className="double-head-table-title"
                          >
                            Exercise
                          </Typography>
                          <Box className="double-head-table-sub">
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                            >
                              Score
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                            >
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: "10rem" }}>
                        <Box className="double-head-table">
                          <Typography
                            variant="font_11_bold_700"
                            className="double-head-table-title"
                          >
                            Assessment
                          </Typography>
                          <Box className="double-head-table-sub">
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                            >
                              Score
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                            >
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: "10rem" }}>
                        <Box className="double-head-table double-head-table-border-right">
                          <Typography
                            variant="font_11_bold_700"
                            className="double-head-table-title"
                          >
                            Total
                          </Typography>
                          <Box className="double-head-table-sub">
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                            >
                              Score
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                            >
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ minWidth: "1.375rem" }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performanceList?.data?.results?.map((row, index) => (
                      <Row key={row.id} row={row} index={index} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Performance;
