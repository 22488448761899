import React from "react";
import "./topicRelatedQuestionsList.scss";
import { Grid, Paper } from "@mui/material";

import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import TopicRelatedQuestionsListCard from "../TopicRelatedQuestionsListCard/TopicRelatedQuestionsListCard";

const TopicRelatedQuestionsList = ({ topics, topicId, assessment }) => {
  const selectedTopic = topics?.results?.find((topic) => topic?.id === topicId);

  return (
    <Grid container className="main-container">
      <Grid item md={12} className="body-container">
        <Paper elevation={0} className="topics-question-parent-container">
          {topicId === null ? (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Topic selected"
              />
            </Grid>
          ) : !selectedTopic?.evaluationQuestions?.results?.length ? (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Questions Available"
              />
            </Grid>
          ) : (
            selectedTopic?.evaluationQuestions?.results?.map(
              (question, index) => (
                <TopicRelatedQuestionsListCard
                  key={index}
                  question={question}
                  assessment={assessment}
                />
              )
            )
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TopicRelatedQuestionsList;
