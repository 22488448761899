import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function fetchEvaluationListingApi(data) {
  let apiUrl = `${apiVersion}/assessmentEvaluation`;
  if (
    data?.assessmentId !== undefined &&
    data?.assessmentId !== null &&
    data?.assessmentId !== ""
  ) {
    apiUrl += `?assessmentId=${data?.assessmentId}`;
  }
  if (
    data?.offset !== undefined &&
    data?.offset !== null &&
    data?.offset !== ""
  ) {
    apiUrl += `&offset=${data?.offset}`;
  }
  if (
    data?.sortBy !== undefined &&
    data?.sortBy !== null &&
    data?.sortBy !== ""
  ) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (
    data?.sortOrder !== undefined &&
    data?.sortOrder !== null &&
    data?.sortOrder !== ""
  ) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  if (
    data?.textSearch !== undefined &&
    data?.textSearch !== null &&
    data?.textSearch !== ""
  ) {
    apiUrl += `&textSearch=${data?.textSearch}`;
  }
  if (data?.type !== undefined && data?.type !== null && data?.type !== "") {
    apiUrl += `&type=${data?.type}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function createEvaluationApi(id) {
  const options = {
    method: "POST",
    url: `${apiVersion}/assessmentEvaluation?assessmentId=${id}`,
    data: id,
  };
  return ApiService(options);
}
export function updateEvaluationApi(data) {
  let apiUrl = `${apiVersion}/assessmentEvaluation`;

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options, true, true);
}
export function sendEvaluationReminderApi({ assessmentId }) {
  let apiUrl = `${apiVersion}/assessmentEvaluation/${assessmentId}`;

  const options = {
    method: "POST",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getAssessmentByIdApi(id) {
  const options = {
    method: "GET",
    url: `${apiVersion}/assessment/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function getBootcampByIdApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}
