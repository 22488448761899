import React from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";

const TopicsList = ({ topics, setTopicId, topicId, totalMarks }) => {
  const handleSelectedTopic = (topicId) => {
    setTopicId(topicId);
  };

  return (
    <Grid item md={12} className="topic-list-container">
      {topics?.totalResults
        ? topics?.results?.map((topic, index) => {
            const TopicTotalMarks = topic?.totalMarks;
            const TopicMarksObtained = topic?.marksObtained;

            return (
              <Box
                key={index}
                className="topic-details"
                onClick={() => handleSelectedTopic(topic?.id)}
                sx={{
                  cursor: "pointer",
                  background: topic?.id === topicId && "#f5f6f8 !important",
                }}
              >
                <Box className="topic-section">
                  <Typography className="eval-topic-btn">
                    {topic?.name}
                  </Typography>
                </Box>
                <Box className="score-section">
                  <Typography variant="font_12">
                    <Chip
                      className="score-chip"
                      label={`${TopicMarksObtained}/${TopicTotalMarks}`}
                      variant={
                        TopicMarksObtained === 0
                          ? "RED"
                          : TopicMarksObtained === TopicTotalMarks
                          ? "GREEN"
                          : "YELLOW"
                      }
                    />
                  </Typography>
                </Box>
              </Box>
            );
          })
        : null}
    </Grid>
  );
};

export default TopicsList;
