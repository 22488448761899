import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./resourceTableModal.scss";
import IconButtons from "../IconButtons/IconButtons";
import {
  cross_default_icon,
  delete_active_icon,
  delete_disabled_icon,
} from "../../constants/icons";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import { checkDateTimeStatus, font_13_icon, font_15 } from "../../utils/utils";
import { backendBaseUrl } from "../../config/config";
import TablePagination from "../TablePagination/TablePagination";
import Constants, { HelpTexts } from "../../constants/constants";
import Loader from "../Loader/Loader";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
const { RESOURCE_BELONGS_TO_TOPIC_NOT_DELETABLE } = HelpTexts;
const onResourceViewClick = (url) => window.open(url, "_blank");

const ResourceTableModal = ({
  open,
  handleClose,
  tableData,
  handleDeleteModalOpen,
  viaSession,
  viaTopic,
  isModuleConcluded,
  isAllowed,
  isResourceLoading,
  accessible = true,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const totalResult = tableData?.totalResults;
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };
  const tableList = useMemo(() => {
    setTotalPages(Math.ceil(totalResult / 5));
    const firstPageIndex = (currentPage - 1) * 5;
    const lastPageIndex = firstPageIndex + 5;
    return tableData?.results?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tableData]);

  const onClose = () => {
    setCurrentPage(1);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} className="resource-modal">
      {isResourceLoading ? (
        <Loader />
      ) : (
        <>
          <DialogTitle>
            <Box className="resource-title">
              <Typography variant="font_16_bold">Resources</Typography>
              <IconButtons
                height={font_15}
                width={font_15}
                image={cross_default_icon}
                handleClick={onClose}
                tooltip="Close"
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            {!isResourceLoading &&
            (!tableData || tableData?.totalResults === 0) ? (
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Resources Available"
              />
            ) : (
              <>
                <Grid container className="resource-table">
                  <TableContainer className="table-container">
                    <Table stickyHeader>
                      <TableHead className="table-head-container">
                        <TableRow className="table-head-row">
                          <TableCell
                            align="left"
                            sx={{
                              paddingLeft: "1rem !important",
                              wordBreak: "break-word",
                              minWidth: "12rem",
                            }}
                          >
                            <Typography variant="font_13_bold">Name</Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "8rem" }}>
                            <Typography variant="font_13_bold">
                              Resource Link
                            </Typography>
                          </TableCell>
                          {(viaTopic || (viaSession && accessible)) && (
                            <TableCell align="center" sx={{ minWidth: "6rem" }}>
                              <Typography
                                variant="font_13_bold"
                                sx={{ padding: "0 1rem" }}
                              >
                                Actions
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableList &&
                          tableList?.map((data) => {
                            const sessionIsScheduled =
                              checkDateTimeStatus(
                                data?.startDate,
                                data?.endDate
                              ) === "future";
                            return (
                              <TableRow
                                key={data?.id}
                                className="table-body-row"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    paddingLeft: "1rem !important",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <Typography variant="font_12">
                                    {data?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <BootstrapTooltip
                                    title={
                                      data?.format === "URL"
                                        ? `${data?.url}`
                                        : ``
                                    }
                                  >
                                    <Button
                                      onClick={onResourceViewClick.bind(
                                        this,
                                        `${
                                          data?.format === "URL"
                                            ? ""
                                            : backendBaseUrl
                                        }${data?.url}`
                                      )}
                                      TypographyClasses
                                      color="secondary"
                                      rel="noopener noreferrer"
                                      variant="outlined"
                                      className="link-btn"
                                      sx={{
                                        width: "6.375rem",
                                        margin: "0 1rem",
                                      }}
                                    >
                                      <Typography
                                        variant="font_10_bold_700"
                                        sx={{
                                          textTransform: "uppercase",
                                          minWidth: "6.25rem",
                                        }}
                                      >
                                        {data?.format === "URL"
                                          ? "View Link"
                                          : "Download File"}
                                      </Typography>
                                    </Button>
                                  </BootstrapTooltip>
                                </TableCell>
                                {((viaTopic &&
                                  data?.permission?.canViewDeleteResource) ||
                                  (viaSession && accessible)) && (
                                  <TableCell align="center">
                                    <IconButtons
                                      disabled={
                                        (viaTopic &&
                                          !data?.permission
                                            ?.canDeleteResource) ||
                                        (!!viaSession &&
                                          (data?.type === Constants.TOPIC ||
                                            !isAllowed))
                                      }
                                      disabledTooltip={
                                        viaTopic ?
                                        !data?.permission?.canDeleteResource
                                          ? `This Resource can be deleted by SPOCs of ${data?.topic?.competency?.name} competency` : null
                                          : !!viaSession
                                          ? data?.type === Constants.TOPIC
                                            ? RESOURCE_BELONGS_TO_TOPIC_NOT_DELETABLE
                                            : !isAllowed
                                            ? "Module is completed"
                                            : null
                                          : !!viaSession
                                          ? data?.type === Constants.TOPIC
                                            ? RESOURCE_BELONGS_TO_TOPIC_NOT_DELETABLE
                                            : isModuleConcluded
                                            ? "Module is completed"
                                            : null
                                          : null
                                      }
                                      disabledImage={delete_disabled_icon}
                                      width={font_13_icon}
                                      height={font_13_icon}
                                      image={delete_active_icon}
                                      tooltip="Delete Resource"
                                      handleClick={() =>
                                        handleDeleteModalOpen(
                                          data?.id,
                                          "Resource"
                                        )
                                      }
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container className="table-pagination-container">
                    <Typography variant="font_16">
                      Total {totalResult}{" "}
                      {totalResult > 1 ? "Records" : "Record"}
                    </Typography>
                    <TablePagination
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ResourceTableModal;
