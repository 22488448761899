import React, { useEffect, useState } from "react";
import "./assessmentListing.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData, deleteUserRole, getUsers } from "../../store/users";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { ROLE_TYPES } from "../../utils/utils";
import AssessmentListingTable from "../../components/AssessmentListingTable/AssessmentListingTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  activateInactivateAssessment,
  getBootcampForAssessment,
  getModuleForAssessment,
  setAssessmentListingData,
} from "../../store/assessmentListing";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import Constants, { HelpTexts } from "../../constants/constants";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

const { COMPLETED } = Constants;
const { CONCLUDED_MODULE, CONCLUDED_BOOTCAMP } = HelpTexts;

const AssessmentListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assessmentData = useSelector((state) => state?.assessmentListing);
  const activateInactivateAssessmentLoader =
    assessmentData?.activateInactivateAssessmentLoader;
  const assessmentDataLoading = assessmentData?.assessmentListingLoader;
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };
  const assessmentList = assessmentData?.assessmentListingData?.data?.results;
  const totalResult = assessmentData?.assessmentListingData?.data?.totalResults;

  const bootcampData = assessmentData?.bootcampData?.data;
  const moduleData = assessmentData?.moduleData?.data;
  //////////////////////////////////////////////////////////////////////
  const [sortBy, setSortBy] = useState("title");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
  };

  const params = useParams();
  const { moduleId, bootcampId } = params;

  const handleEditAssessment = (e, assessment) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment-form`
        : `/bootcamp/${bootcampId}/assessment-form`,
      {
        state: {
          assessmentId: assessment?.id,
          disabledSessionsId: assessment?.sessions?.results?.map(
            (item) => item?.id
          ),
        },
      }
    );
  };

  const handleActivateInactivateAssessment = async (
    e,
    assessmentId,
    active
  ) => {
    await dispatch(activateInactivateAssessment({ id: assessmentId, active }));
    await dispatch(
      setAssessmentListingData({
        bootcampId: bootcampId,
        moduleId: moduleId,
        sortBy,
        sortOrder: orderBy,
        offset,
      })
    );
  };

  const handleCreateAssessmentOpen = () => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment-form`
        : `/bootcamp/${bootcampId}/assessment-form`
    );
  };

  ///////////
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getBootcampForAssessment({ bootcampId }));
      if (moduleId) {
        await dispatch(getModuleForAssessment({ moduleId }));
      }
      await dispatch(
        setAssessmentListingData({
          bootcampId: bootcampId,
          moduleId: moduleId,
          sortBy,
          sortOrder: orderBy,
          // max,
          offset,
          // paginated,
          // type,
        })
      );

      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, sortBy, orderBy, offset]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    return () => {
      dispatch(clearUserData());
    };
  }, []);

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: moduleData?.name ? moduleData?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${moduleData?.id}` || "/",
        },
        {
          label: "Assessments",
        },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
        },
      ];

  if (
    assessmentDataLoading ||
    isLoading ||
    assessmentDataLoading ||
    activateInactivateAssessmentLoader
  )
    return <Loader />;
  const isBootcampCompleted = bootcampData?.status === COMPLETED;
  const isBootcampSpoc = bootcampData?.permission?.spoc;
  const isModuleCompleted = moduleData?.status === COMPLETED;
  const isModuleSpoc = moduleData?.permission?.spoc;

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Assessments</Typography>
          <BootstrapTooltip
            title={
              moduleId
                ? !isModuleSpoc
                  ? "Accessible to spoc only"
                  : isModuleCompleted
                  ? CONCLUDED_MODULE
                  : ""
                : !isBootcampSpoc
                ? "Accessible to spoc only"
                : isBootcampCompleted
                ? CONCLUDED_BOOTCAMP
                : ""
            }
          >
            <Box className="header-right-icons">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCreateAssessmentOpen}
                className="margin-right-1"
                disabled={
                  moduleId
                    ? !isModuleSpoc || isModuleCompleted
                    : !isBootcampSpoc || isBootcampCompleted
                }
              >
                <Typography variant="outlineBtnLabel">
                  Create Assessment
                </Typography>
              </Button>
            </Box>
          </BootstrapTooltip>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column user-paper-container"
            >
              {!assessmentDataLoading && !assessmentList?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Assessments Found"
                  />
                </Grid>
              ) : (
                assessmentList?.length > 0 && (
                  <AssessmentListingTable
                    assessmentList={assessmentList}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleSorting={handleSorting}
                    sortBy={sortBy}
                    orderBy={orderBy}
                    viaModule={moduleId}
                    totalResult={totalResult}
                    handleEditAssessment={handleEditAssessment}
                    handleActivateInactivateAssessment={
                      handleActivateInactivateAssessment
                    }
                  />
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AssessmentListing;
