import React, { useEffect, useState } from "react";
import "./viewAssessmentEvaluation.scss";
import "./viewAssessmentEvaluationPanel.scss";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import ViewAssessmentEvaluationPanel from "./ViewAssessmentEvaluationPanel";
import TopicsList from "./TopicsList";
import TopicRelatedQuestionsList from "../../components/TopicRelatedQuestionsList/TopicRelatedQuestionsList";
import {
  clearAssessmentEvaluationData,
  getAssessmentEvaluationById,
  getBootcampById,
} from "../../store/viewAssessmentEvaluation";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";

const ViewAssessmentEvaluation = () => {
  const dispatch = useDispatch();
  const assessmentEvaluation = useSelector(
    (state) => state?.viewAssessmentEvaluation
  );
  const assessmentEvaluationLoading =
    assessmentEvaluation?.getAssessmentEvaluationLoader;
  const assessmentEvaluationData =
    assessmentEvaluation?.assessmentEvaluationData?.data;

  const [isLoading, setIsLoading] = useState(true);
  const bootcampData = assessmentEvaluation?.bootcampData?.data;
  const module = assessmentEvaluationData?.assessment?.module;
  const params = useParams();
  const { bootcampId, moduleId, assessmentId, evaluationId } =
    params;

  ///////////
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getBootcampById({ bootcampId }));
      await dispatch(getAssessmentEvaluationById(evaluationId));
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearAssessmentEvaluationData());
    };
  }, []);

  const [topicId, setTopicId] = useState(null);

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: module?.name ? module?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${module?.id}` || "/",
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/module/${moduleId}/assessments` || "/",
        },
        {
          label: "Evaluations",
          url:
            `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations` ||
            "/",
        },
        { label: assessmentEvaluationData?.assessment?.title },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/assessments` || "/",
        },
        {
          label: "Evaluations",
          url:
            `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations` ||
            "/",
        },
        { label: assessmentEvaluationData?.assessment?.title },
      ];

  if (assessmentEvaluationLoading || isLoading || assessmentEvaluationLoading)
    return <Loader />;
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Assessment</Typography>
          <Box className="header-right-icons">
            <BootstrapTooltip title={null}></BootstrapTooltip>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Paper
            elevation={0}
            className="flex-container-column assessment-paper-container"
          >
            <ViewAssessmentEvaluationPanel
              assessmentInfo={assessmentEvaluationData}
            />

            <Box className="margin-bottom-5">
              <Grid container className="assessment-panel-container">
                <Grid item md={12} sx={{ padding: "0.5rem 1rem" }}>
                  <Box>
                    <Typography variant="font_20">
                      Attempted Questions
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Grid
                    container
                    item
                    md={12}
                    className="topics-parent-container"
                  >
                    {assessmentEvaluationData?.topics?.results?.length ? (
                      <TopicsList
                        topicId={topicId}
                        topics={assessmentEvaluationData?.topics}
                        setTopicId={setTopicId}
                        totalMarks={assessmentEvaluationData?.totalMarks}
                      />
                    ) : (
                      <Grid container className="no-data-container">
                        <NoDataAvailable
                          imgHeight={4}
                          imgWidth={4.5}
                          message="No Topics Available"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item md={8} sm={12}>
                  <Grid container item md={12} className="">
                    <TopicRelatedQuestionsList
                      topics={assessmentEvaluationData?.topics}
                      assessment={assessmentEvaluationData?.assessment}
                      topicId={topicId}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewAssessmentEvaluation;
