import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function fetchAssessmentQuestionsApi(data) {
  let apiUrl = `${apiVersion}/assessmentQuestion`;
  if (
    data?.topicId !== undefined &&
    data?.topicId !== null &&
    data?.topicId !== ""
  ) {
    apiUrl += `?topicId=${data?.topicId}`;
  }
  if (data?.title !== undefined && data?.title !== null && data?.title !== "") {
    apiUrl += `&title=${data?.title}`;
  }
  if (
    data?.type?.length &&
    data?.type !== undefined &&
    data?.type !== null &&
    data?.type !== ""
  ) {
    apiUrl += `&type=${data?.type}`;
  }
  if (
    data?.difficultyLevel?.length &&
    data?.difficultyLevel !== undefined &&
    data?.difficultyLevel !== null &&
    data?.difficultyLevel !== ""
  ) {
    apiUrl += `&difficultyLevel=${data?.difficultyLevel}`;
  }
  if (data?.showActive !== undefined && data?.showActive !== "") {
    apiUrl += `&showActive=${data?.showActive}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
export function addAssessmentQuestionApi(data) {
  let apiUrl = `${apiVersion}/assessmentQuestion`;
  if (
    data?.topicId !== undefined &&
    data?.topicId !== null &&
    data?.topicId !== ""
  ) {
    apiUrl += `?topicId=${data?.topicId}`;
  }
  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function editAssessmentQuestionApi(data) {
  let apiUrl = `${apiVersion}/assessmentQuestion`;
  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function toggleQuestionAssessmentApi(id) {
  let apiUrl = `${apiVersion}/assessmentQuestion/toggleActive?questionId=${id}`;
  const options = {
    method: "POST",
    url: apiUrl,
    data: id,
  };
  return ApiService(options);
}

export function addOptionApi(data) {
  let apiUrl = `${apiVersion}/assessmentQuestionOption`;
  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function updateOptionApi(data) {
  let apiUrl = `${apiVersion}/assessmentQuestionOption`;
  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function deleteOptionApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/assessmentQuestionOption/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function deleteAssessmentQuestionApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/assessmentQuestion/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function getTopicByIdApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/topics/${data.id}`,
    data: data,
  };
  return ApiService(options);
}
