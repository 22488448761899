import React, { useState } from "react";
import "./topicRelatedQuestionsListCard.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { font_13 } from "../../utils/utils";
import { down_arrow_icon, up_arrow_icon } from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import { HelpTexts } from "../../constants/constants";

const { INTERVIEW } = HelpTexts;

const TopicRelatedQuestionsListCard = ({ question, assessment }) => {
  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const { markPerEasyQuestion, markPerMediumQuestion, markPerHardQuestion } =
    assessment || {};

  const questionType = question?.question?.difficultyLevel;
  const questionMarksObtained = question?.marksObtained || 0;
  const questionTotalMarks =
    questionType === "EASY"
      ? markPerEasyQuestion
      : questionType === "MEDIUM"
      ? markPerMediumQuestion
      : questionType === "HARD"
      ? markPerHardQuestion
      : null;
  const isTypeInterview = assessment?.type === INTERVIEW ? true : false;
  const options = question?.options;

  return (
    <Accordion
      className="accordion"
      key={question?.id}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        className="accordion-summary"
        aria-controls="panel1-content"
        id={`panel-header-${question?.id}`}
      >
        <Box className="topic-question-head-container">
          <Box className="topic-question-head-left margin-right-2">
            <Typography variant="font_14">
              {question?.question?.title}
            </Typography>
          </Box>
          <Box className="topic-question-head-right">
            <Typography variant="font_12" className="margin-right-1">
              <Chip
                className="score-chip"
                label={`${questionMarksObtained}/${questionTotalMarks}`}
                variant={
                  questionMarksObtained === 0
                    ? "RED"
                    : questionMarksObtained === questionTotalMarks
                    ? "GREEN"
                    : "YELLOW"
                }
              />
            </Typography>
            <IconButtons
              height={font_13}
              width={font_13}
              image={expanded ? up_arrow_icon : down_arrow_icon}
            />
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails className="accordion-details">
        <Box>
          <Typography variant="font_12">
            {!isTypeInterview && (
              <>
                <Typography variant="font_12_bold">Options: </Typography>
                <br />
                {options?.map((option) => {
                  const chosenOption = option?.chosenOption;
                  const correct = option?.correct;

                  return (
                    <Box
                      className="question-options"
                      sx={{
                        backgroundColor:
                          chosenOption && !correct
                            ? "#fc9b9b"
                            : correct
                            ? "#b2eaca"
                            : "",
                      }}
                    >
                      <Typography key={option.id} variant="font_12">
                        {option?.optionText}
                      </Typography>
                    </Box>
                  );
                })}
              </>
            )}
            <Typography variant="font_12_bold">Description:</Typography>{" "}
            {question?.question?.description}
            <br />
            <Typography variant="font_12_bold">Feedback:</Typography>{" "}
            {question?.interviewerFeedback}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TopicRelatedQuestionsListCard;
