import React, { useEffect, useRef, useState } from "react";
import "./evaluationListing.scss";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData } from "../../store/users";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createEvaluation,
  getAssessmentById,
  getBootcampById,
  sendEvaluationReminder,
  setEvaluationListingData,
} from "../../store/evaluationListing";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import { HelpTexts } from "../../constants/constants";
import EvaluationListingTable from "../../components/EvaluationListingTable/EvaluationListingTable";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";

const { INTERVIEW, MCQ } = HelpTexts;

const EvaluationListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { viaAssessmentView } = location?.state || {};
  const evaluationData = useSelector((state) => state?.evaluationListing);
  const evaluationDataLoading = evaluationData?.evaluationListingLoader;
  const sendEvaluationReminderLoader =
    evaluationData?.sendEvaluationReminderLoading;
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };
  const evaluationListing =
    evaluationData?.evaluationListingData?.data?.results;
  const totalResult = evaluationData?.evaluationListingData?.data?.totalResults;
  const assessmentData = evaluationData?.assessmentData?.data;
  const assessmentDataPermissions = assessmentData?.permission || {};
  const {
    canViewCreateEvaluations,
    canCreateEvaluations,
    canViewSendReminder,
    canSendReminder,
    createEvaluationsMessage,
    sendReminderMessage,
  } = assessmentDataPermissions;
  const bootcampData = evaluationData?.bootcampData?.data;
  const module = assessmentData?.module;
  //////////////////////////////////////////////////////////////////////
  const [sortBy, setSortBy] = useState("attendeeName");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
  };

  const params = useParams();
  const { moduleId, bootcampId, assessmentId } = params;
  const assessmentType = assessmentData?.type;
  const isTypeInterview = assessmentType === INTERVIEW ? true : false;

  const handleEditEvaluation = (e, evaluationId) => {
    // navigate(
    //   moduleId
    //     ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/create-assessment`
    //     : `/bootcamp/${bootcampId}/assessment/create-assessment`,
    //   { state: { evaluationId } }
    // );
  };
  const handleReopenEvaluationModal = (evaluationId) => {
    console.log("reopen", evaluationId);
  };

  const handleViewEvaluation = (evaluationId) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations/${evaluationId}`
        : `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations/${evaluationId}`,
      { state: { evaluationId } }
    );
  };

  const handleCreateEvaluationOpen = async () => {
    setIsLoading(true);
    const apiValue = await dispatch(createEvaluation(assessmentId));
    if (apiValue?.error?.message === "Rejected") {
      return;
    } else {
      await dispatch(
        setEvaluationListingData({
          assessmentId,
          bootcampId,
          moduleId,
          sortBy,
          sortOrder: orderBy,
          offset,
          type: assessmentType,
        })
      );
    }
    setIsLoading(false);
  };

  ////////////
  const handleSendEvaluationReminder = async () => {
    await dispatch(sendEvaluationReminder({ assessmentId }));
  };

  ////////////
  const [filterBy, setFilterBy] = useState("");
  const inputRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleFilterChange = (value) => {
    setFilterBy(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      dispatch(
        setEvaluationListingData({
          assessmentId,
          textSearch: value,
        })
      ).then(() => {
        setTimeout(() => {
          inputRef?.current?.focus();
        }, 500);
      });
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  ///////////
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getBootcampById({ bootcampId }));
      await dispatch(getAssessmentById(assessmentId));
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchEvaluationList = async () => {
      setIsLoading(true);
      if (assessmentType) {
        await dispatch(
          setEvaluationListingData({
            assessmentId,
            bootcampId,
            moduleId,
            sortBy,
            sortOrder: orderBy,
            offset,
            type: assessmentType,
          })
        );
      }
      setIsLoading(false);
    };
    fetchEvaluationList();
  }, [dispatch, sortBy, orderBy, offset, assessmentType]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    return () => {
      dispatch(clearUserData());
    };
  }, []);

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: module?.name ? module?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${module?.id}` || "/",
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/module/${module?.id}/assessments`,
        },
        ...(viaAssessmentView
          ? [
              {
                label: assessmentData?.title,
                url: `/bootcamp/${bootcampId}/module/${module?.id}/assessment/${assessmentId}`,
              },
            ]
          : []),
        {
          label: "Evaluations",
        },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/assessments`,
        },
        ...(viaAssessmentView
          ? [
              {
                label: assessmentData?.title,
                url: `/bootcamp/${bootcampId}/assessment/${assessmentId}`,
              },
            ]
          : []),
        {
          label: "Evaluations",
        },
      ];
  if (evaluationDataLoading || sendEvaluationReminderLoader || isLoading)
    return <Loader />;
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />

      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">
            {assessmentData?.title}
          </Typography>
          <Box className="header-right-icons">
            <TextField
              size="small"
              placeholder="Search"
              color="secondary"
              value={filterBy}
              onChange={(e) => handleFilterChange(e?.target?.value)}
              className="margin-right-1 evaluation-search-input"
              type="search"
              inputRef={inputRef}
            />
            {canViewSendReminder && (
              <BootstrapTooltip
                title={!canSendReminder ? sendReminderMessage : null}
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="action-head-btn margin-right-1"
                    onClick={handleSendEvaluationReminder}
                    disabled={!canSendReminder}
                  >
                    <Typography variant="outlineBtnLabel">
                      send reminder
                    </Typography>
                  </Button>
                </Box>
              </BootstrapTooltip>
            )}
            {canViewCreateEvaluations && (
              <BootstrapTooltip
                title={!canCreateEvaluations ? createEvaluationsMessage : null}
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCreateEvaluationOpen}
                    sx={{ height: "1.875rem" }}
                    disabled={!canCreateEvaluations}
                  >
                    <Typography variant="outlineBtnLabel">
                      Create Evaluations
                    </Typography>
                  </Button>
                </Box>
              </BootstrapTooltip>
            )}{" "}
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column user-paper-container"
            >
              {!evaluationDataLoading && !evaluationListing?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Evaluations Found"
                  />
                </Grid>
              ) : (
                evaluationListing?.length > 0 && (
                  <EvaluationListingTable
                    isTypeInterview={isTypeInterview}
                    evaluationListing={evaluationListing}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleSorting={handleSorting}
                    sortBy={sortBy}
                    orderBy={orderBy}
                    viaModule={moduleId}
                    totalResult={totalResult}
                    handleEditEvaluation={handleEditEvaluation}
                    handleReopenEvaluationModal={handleReopenEvaluationModal}
                    handleViewEvaluation={handleViewEvaluation}
                  />
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EvaluationListing;
