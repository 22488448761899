import React, { useEffect, useState } from "react";
import "./viewAssessment.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import ViewAssessmentInfo from "./ViewAssessmentInfo";
import {
  clearAssessmentInfo,
  getAssessmentById,
} from "../../store/createAssessment";
import AssessmentViewActionItems from "./AssessmentViewActionItems";
import {
  activateInactivateAssessment,
  setAssessmentListingData,
} from "../../store/assessmentListing";
import { getBootcampById } from "../../store/bootcamp";

const ViewAssessment = () => {
  const { bootcampId, moduleId, assessmentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assessmentData = useSelector((state) => {
    return state?.createAssessment;
  });
  const assessmentInfo = assessmentData?.assessmentInfo?.data;
  const assessmentQuestionsData = assessmentData?.assessmentQuestionsData?.data;
  const module = assessmentInfo?.module;

  const getAssessmentLoader = assessmentData?.getAssessmentLoader;
  const [isLoading, setIsLoading] = useState(true);

  const getBootcampData = useSelector((state) => {
    return state?.bootcamp;
  });
  const BootcampData = getBootcampData?.getBootcampDataById?.data;

  const handleEditAssessment = (e, assessmentId) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment-form`
        : `/bootcamp/${bootcampId}/assessment-form`,
      { state: { assessmentId, viaAssessmentView: true } }
    );
  };
  const handleViewEvaluations = (e, assessmentId) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations`
        : `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations`,
      { state: { assessmentId, viaAssessmentView: true } }
    );
  };

  const handleActivateInactivateAssessment = async (
    e,
    assessmentId,
    active
  ) => {
    await dispatch(activateInactivateAssessment({ id: assessmentId, active }));
    await dispatch(
      setAssessmentListingData({
        bootcampId: bootcampId,
        moduleId: moduleId,
      })
    );
  };

  /////////////////////////////////////////////////////

  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: BootcampData?.name ? BootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: module?.name ? module?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${module?.id}` || "/",
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/module/${module?.id}/assessments`,
        },
        { label: assessmentInfo?.title },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: BootcampData?.name ? BootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/assessments`,
        },
        { label: assessmentInfo?.title },
      ];

  useEffect(() => {
    const fetchData = async () => {
      let assessmentApiValue;
      setIsLoading(true);
      await dispatch(getBootcampById({ bootcampId }));
      assessmentApiValue = dispatch(getAssessmentById(assessmentId));
      setIsLoading(false);
    };

    fetchData();

    return () => {
      dispatch(clearAssessmentInfo());
    };
  }, [dispatch]);

  if ((getAssessmentLoader && isLoading) || isLoading) return <Loader />;

  return (
    <>
      {!getAssessmentLoader && !assessmentInfo ? (
        <Grid container className="module-no-data-container">
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="Assessment Data not Available"
          />
        </Grid>
      ) : (
        <>
          <BreadCrumb breadcrumbs={breadcrumbs} />

          <Grid
            item
            className="header-container view-assessment-header margin-bottom"
            md={12}
          >
            <Box className="view-assessment-header">
              <Typography variant="font_21_bold" className="margin-right-2">
                {assessmentInfo?.title}
              </Typography>
              <Chip
                label={assessmentInfo?.type}
                variant="QUEST_TYPE"
                className="margin-right-2 module-chip-status"
              />
            </Box>
            <Box className="header-right-icons">
              <AssessmentViewActionItems
                assessmentId={assessmentInfo?.id}
                isAssessmentActive={assessmentInfo?.active}
                permissions={assessmentInfo?.permission}
                handleActivateInactivateAssessment={
                  handleActivateInactivateAssessment
                }
                handleEditAssessment={handleEditAssessment}
                handleViewEvaluations={handleViewEvaluations}
              />
            </Box>
          </Grid>

          <Grid item md={12} className="body-container">
            <Paper
              elevation={0}
              className="flex-container-column view-assessment-paper-container"
            >
              <ViewAssessmentInfo
                infoData={assessmentInfo}
                assessmentQuestionsData={assessmentQuestionsData}
              />
            </Paper>
          </Grid>
        </>
      )}
    </>
  );
};

export default ViewAssessment;
