export const ROUTE_CONSTANTS = {
  LOGIN: "/login",
  HOME: "/",
  DASHBOARD: "/dashboard",
  LOCATIONS_AND_ROOMS: "locations-and-rooms",
  TOPIC: "topic",
  USERS: "users",
  SESSIONS: "/bootcamp/:bootcampId/module/:moduleId/sessions",
  TOPIC_SESSIONS: "/topic/:topicId/sessions",
  TOPIC_SESSION_VIEW: "/topic/:topicId/sessions/:sessionId",
  QUESTION_VIEW: "topic/:topicId/questions",
  MODULE_SESSIONS_QUESTION_VIEW:
    "/bootcamp/:bootcampId/module/:moduleId/sessions/:sessionId/questions",
  SESSIONS_QUESTION_VIEW: "topic/:topicId/sessions/:sessionId/questions",
  SESSION_VIEW: "/bootcamp/:bootcampId/module/:moduleId/sessions/:sessionId",
  BOOTCAMP_MODULE: "/bootcamp/:bootcampId/module/:moduleId",
  ATTENDEES_BOOTCAMP: "/bootcamp/:bootcampId/attendees",
  ATTENDEES_MODULE: "/bootcamp/:bootcampId/module/:moduleId/attendees",
  EXERCISES: "/exercises/:user",
  ACCESS_DENIED: "/error",
  PERFORMANCE: "/performance/:user",
  ASSESSMENT_QUESTIONS: "topic/:topicId/assessmentQuestions",
  BOOTCAMP_CREATE_ASSESSMENT: "/bootcamp/:bootcampId/assessment-form",
  MODULE_CREATE_ASSESSMENT:
    "/bootcamp/:bootcampId/module/:moduleId/assessment-form",
  BOOTCAMP_VIEW_ASSESSMENT: "/bootcamp/:bootcampId/assessment/:assessmentId",
  MODULE_VIEW_ASSESSMENT:
    "/bootcamp/:bootcampId/module/:moduleId/assessment/:assessmentId",
  BOOTCAMP_ASSESSMENT_LISTING: "/bootcamp/:bootcampId/assessments",
  MODULE_ASSESSMENT_LISTING:
    "/bootcamp/:bootcampId/module/:moduleId/assessments",
  BOOTCAMP_ASSESSMENT_EVALUATION_LISTING:
    "/bootcamp/:bootcampId/assessment/:assessmentId/evaluations",
  MODULE_ASSESSMENT_EVALUATION_LISTING:
    "/bootcamp/:bootcampId/module/:moduleId/assessment/:assessmentId/evaluations",
  BOOTCAMP_ASSESSMENT_VIEW_EVALUATION_LISTING:
    "/bootcamp/:bootcampId/assessment/:assessmentId/evaluations/:evaluationId",
  MODULE_ASSESSMENT_VIEW_EVALUATION_LISTING:
    "/bootcamp/:bootcampId/module/:moduleId/assessment/:assessmentId/evaluations/:evaluationId",
};
