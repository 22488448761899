import React, { useEffect } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  calander_active_icon,
  delete_active_icon,
  delete_disabled_icon,
  down_arrow_icon,
  edit_active_icon,
  edit_disabled_icon,
  laptop_active_icon,
  listing_disabled_icon,
  listing_icon,
  plus_active_completed_icon,
  plus_active_ongoing_icon,
  plus_active_scheduled_icon,
  spoc_active_icon,
  trainee_active_icon,
  up_arrow_icon,
  user_setting_active_icon,
  users_active_icon,
} from "../../constants/icons";
import { font_13, getBootcampStatus, getDate } from "./../../utils/utils";
import BootcampModuleExpand from "./BootcampModuleExpand";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import Constants from "../../constants/constants";

const { ACTIVE, COMPLETED } = Constants;

const HomeTable = ({
  bootcampData,
  subModules,
  expandIndex,
  handleExpandSubModule,
  handleDeleteModalOpen,
  handleCreateBootcampModalOpen,
  handleEditBootcamp,
  handleEditBootcampModule,
  moduleLoading,
  isLoading,
  isHr,
  isHrOnly,
  bootcampRefs,
  expandBootcampId,
  isHrAndAdmin,
}) => {
  const navigate = useNavigate();
  const handleListAssessments = (e, bootcamp, module) => {
    e.stopPropagation();
    if (module?.id) {
      navigate(`/bootcamp/${bootcamp?.id}/module/${module?.id}/assessments`);
    } else {
      navigate(`/bootcamp/${bootcamp?.id}/assessments`);
    }
  };

  return (
    <>
      {bootcampData?.map((bootcamp, index) => {
        const truePermissions = Object.keys(bootcamp?.permission)
          .filter((key) => bootcamp?.permission[key])
          .map((key) => {
            switch (key) {
              case "spoc":
                return "SPOC";
              case "sessionPresenter":
                return "Session Presenter";
              case "mentor":
                return "Mentor";
              case "attendee":
                return "Attendee";
              case "exerciseReviewer":
                return "Exercise Reviewer";
              default:
                return null;
            }
          })
          .sort((a, b) => a?.localeCompare(b));

        const isBootcampScheduled = bootcamp?.status === "SCHEDULED";
        const isBootcampActive = bootcamp?.status === "ACTIVE";
        const isBootcampConcluded = bootcamp?.status === "COMPLETED";
        const canViewActions =
          bootcamp?.permission?.spoc &&
          (bootcamp?.status === "ACTIVE" || isBootcampScheduled);
        const canViewBootcampActions = bootcamp?.permission?.spoc;
        const bootcampStatus = getBootcampStatus(bootcamp?.status);

        const spocsName = bootcamp?.spocs?.results
          ?.map((spoc) => spoc?.fullName)
          ?.sort()
          ?.map((name, index, arr) => (
            <React.Fragment key={index}>
              {name}
              {index < arr.length - 1 && ", "}
            </React.Fragment>
          ));

        return (
          <Accordion
            ref={(el) => (bootcampRefs.current[index] = el)}
            className={`home-main-cell-container ${bootcampStatus?.name.toLowerCase()}`}
            key={index}
            expanded={expandIndex === bootcamp?.id}
          >
            <AccordionSummary
              id={index && isLoading}
              className="home-cell-container"
              onClick={() => handleExpandSubModule(bootcamp?.id, index)}
            >
              <Box
                className="module-left-container"
                sx={{
                  backgroundColor: bootcampStatus?.color,
                  color: bootcampStatus?.text,
                }}
              >
                <Typography
                  variant="font_15_bold"
                  fontWeight={500}
                  className="module-left-head module-left-head-nowrap"
                >
                  {bootcampStatus?.name}
                </Typography>
              </Box>
              <Box className="module-right-container">
                <Box className="home-cell-head-container">
                  <Box className="home-cell-main-head">
                    <Typography
                      variant="font_15_bold"
                      className="home-cell-heading"
                    >
                      {bootcamp?.name}
                    </Typography>
                  </Box>

                  <Box>
                    {canViewBootcampActions && (
                      <>
                        <IconButtons
                          height={font_13}
                          width={font_13}
                          image={listing_icon}
                          handleClick={(e) =>
                            handleListAssessments(e, bootcamp)
                          }
                          classList={`margin-right-1`}
                          tooltip="List Assessments"
                        />
                        <IconButtons
                          height={font_13}
                          width={font_13}
                          image={edit_active_icon}
                          handleClick={(e) => handleEditBootcamp(e, bootcamp)}
                          classList={`margin-right-1 ${
                            isBootcampConcluded ? "cursor-auto" : ""
                          }`}
                          tooltip="Edit Bootcamp"
                          disabledImage={edit_disabled_icon}
                          disabled={isBootcampConcluded}
                          disabledTooltip={
                            isBootcampConcluded ? "Bootcamp is completed" : null
                          }
                        />

                        <IconButtons
                          height={font_13}
                          width={font_13}
                          image={delete_active_icon}
                          handleClick={(e) =>
                            handleDeleteModalOpen(e, bootcamp, "bootcamp")
                          }
                          tooltip="Delete Bootcamp"
                          classList={`margin-right-1 ${
                            isBootcampActive || isBootcampConcluded
                              ? "cursor-auto"
                              : ""
                          }`}
                          disabledImage={delete_disabled_icon}
                          disabled={isBootcampActive || isBootcampConcluded}
                          disabledTooltip={
                            isBootcampConcluded
                              ? "Bootcamp is completed"
                              : isBootcampActive
                              ? "Bootcamp is active"
                              : null
                          }
                        />
                      </>
                    )}
                    <IconButtons
                      height={font_13}
                      width={font_13}
                      image={
                        expandIndex === bootcamp?.id
                          ? up_arrow_icon
                          : down_arrow_icon
                      }
                      tooltip={
                        expandIndex === bootcamp?.id
                          ? "Hide Module"
                          : "Show Module"
                      }
                    />
                  </Box>
                </Box>
                <Grid
                  container
                  spacing={1}
                  rowSpacing={1}
                  className="home-info-container"
                >
                  <Grid
                    item
                    sm={2}
                    md={3}
                    lg={1.5}
                    xl={1.5}
                    twoK={1.5}
                    fourK={1.5}
                    className="home-dates-container"
                  >
                    <Box className="dates-head-container">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={user_setting_active_icon}
                        tooltip="Competency"
                      />
                      <Typography
                        variant="font_12_bold"
                        className="dates-head-title"
                      >
                        Competency
                      </Typography>
                    </Box>
                    <Box className="dates-body-container">
                      <Typography variant="font_12_bold">
                        {bootcamp?.competency?.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    md={3}
                    lg={1.5}
                    xl={1.5}
                    twoK={1.5}
                    fourK={1.5}
                    className="home-dates-container"
                  >
                    <Box className="dates-head-container">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={calander_active_icon}
                        tooltip="Start Date"
                      />
                      <Typography
                        variant="font_12_bold"
                        className="dates-head-title"
                      >
                        Start Date
                      </Typography>
                    </Box>
                    <Box className="dates-body-container">
                      <Typography variant="font_12_bold">
                        {getDate(bootcamp?.startDate)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    md={3}
                    lg={1.5}
                    xl={1.5}
                    twoK={1.5}
                    fourK={1.5}
                    className="home-dates-container"
                  >
                    <Box className="dates-head-container">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={calander_active_icon}
                        tooltip="End Date"
                      />
                      <Typography
                        variant="font_12_bold"
                        className="dates-head-title"
                      >
                        End Date
                      </Typography>
                    </Box>
                    <Box className="dates-body-container">
                      <Typography variant="font_12_bold">
                        {getDate(bootcamp?.endDate)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    md={3}
                    lg={1.5}
                    xl={1.5}
                    twoK={1.5}
                    fourK={1.5}
                    className="home-dates-container"
                  >
                    <Box className="dates-head-container">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={trainee_active_icon}
                        tooltip="Attendees Count"
                      />
                      <Typography
                        variant="font_12_bold"
                        className="dates-head-title"
                      >
                        Attendees
                      </Typography>
                    </Box>
                    <Box className="dates-body-container">
                      <Typography
                        color={
                          (bootcamp?.permission?.spoc || isHr) && "secondary"
                        }
                        variant="font_12_bold"
                        className="cursor-pointer"
                      >
                        <Link
                          to={
                            (bootcamp?.permission?.spoc || isHr) &&
                            `/bootcamp/${bootcamp.id}/attendees`
                          }
                        >
                          {bootcamp?.permission?.spoc || isHr ? (
                            <u>{bootcamp.attendees?.totalResults}</u>
                          ) : (
                            bootcamp.attendees?.totalResults
                          )}
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    md={3}
                    lg={1.5}
                    xl={1.5}
                    twoK={1.5}
                    fourK={2}
                    className="home-dates-container"
                  >
                    <Box className="dates-head-container">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={laptop_active_icon}
                        tooltip="Modules Count"
                      />
                      <Typography
                        variant="font_12_bold"
                        className="dates-head-title"
                      >
                        Modules
                      </Typography>
                    </Box>
                    <Box className="dates-body-container">
                      <Typography variant="font_12_bold" color="secondary">
                        <u>{bootcamp?.modules?.totalResults}</u>
                      </Typography>
                    </Box>
                  </Grid>
                  {!(isHrOnly || isHrAndAdmin) && (
                    <Grid
                      item
                      sm={2}
                      md={4}
                      lg={1.5}
                      xl={2}
                      twoK={2.25}
                      fourK={2}
                      className="home-dates-container"
                    >
                      <Box className="dates-head-container">
                        <IconButtons
                          width={font_13}
                          height={font_13}
                          image={spoc_active_icon}
                          tooltip="My Roles"
                        />
                        <Typography
                          variant="font_12_bold"
                          className="dates-head-title"
                        >
                          My Roles
                        </Typography>
                      </Box>
                      <Box className="dates-body-container">
                        <Typography variant="font_12_bold">
                          {truePermissions.toString().split(",").join(", ")}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    item
                    sm={isHr ? 4 : 2}
                    md={isHr ? 8 : 4}
                    lg={isHr ? 4 : 2.5}
                    xl={isHr ? 4 : 2}
                    twoK={isHr ? 4.5 : 2.25}
                    fourK={isHr ? 4 : 2}
                    className="home-dates-container"
                  >
                    <Box className="dates-head-container">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={users_active_icon}
                        tooltip="SPOC"
                      />
                      <Typography
                        variant="font_12_bold"
                        className="dates-head-title"
                      >
                        SPOC
                      </Typography>
                    </Box>
                    <Box className="dates-body-container">
                      <Typography variant="font_12_bold" key={index}>
                        {spocsName}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="expanded-module-collapse">
              {moduleLoading ? (
                <Loader />
              ) : (
                <BootcampModuleExpand
                  bootcamp={bootcamp}
                  modules={subModules}
                  handleDeleteModalOpen={handleDeleteModalOpen}
                  handleEditBootcampModule={handleEditBootcampModule}
                  moduleLoading={moduleLoading}
                  isLoading={isLoading}
                  isHr={isHr}
                  expandBootcampId={expandBootcampId}
                  isBootcampConcluded={isBootcampConcluded}
                  handleListAssessments={handleListAssessments}
                />
              )}
            </AccordionDetails>
            <AccordionActions>
              {canViewActions && (
                <Grid container>
                  <Button
                    variant="outlined"
                    color={bootcampStatus?.name.toLowerCase()}
                    fullWidth
                    startIcon={
                      <IconButtons
                        height={0.625}
                        width={0.625}
                        image={
                          bootcampStatus?.name === ACTIVE
                            ? plus_active_ongoing_icon
                            : bootcampStatus?.name === COMPLETED
                            ? plus_active_completed_icon
                            : plus_active_scheduled_icon
                        }
                      />
                    }
                    className={`bootcamp-module-add-btn ${bootcampStatus?.name.toLowerCase()}`}
                    onClick={() => handleCreateBootcampModalOpen(bootcamp)}
                  >
                    <Typography variant="font_11_bold">ADD MODULE</Typography>
                  </Button>
                </Grid>
              )}
            </AccordionActions>
          </Accordion>
        );
      })}
    </>
  );
};

export default HomeTable;
