import React, { useState } from "react";
import "./assessmentQuestionsCard.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import {
  black_plus_default_icon,
  clone_active_icon,
  clone_disabled_icon,
  delete_active_icon,
  delete_disabled_icon,
  down_arrow_icon,
  edit_active_icon,
  edit_disabled_icon,
  plus_active_icon,
  plus_disabled_icon,
  up_arrow_icon,
} from "../../constants/icons";
import { Box } from "@mui/system";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import { font_10, font_13, font_13_icon } from "./../../utils/utils";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const AssessmentQuestionsCard = ({
  questionEditModal,
  deleteModalOpenQuestion,
  cloneQuestionModalOpen,
  id,
  optionEditModal,
  handleOptionModalOpen,
  deleteModalOpenOption,
  question,
  toggleQuestionModalOpen,
}) => {
  const permission = question?.permission;
  const questionTypes = useSelector(
    (state) => state?.utility?.constants?.questionTypes
  );
  const difficultyLevels = useSelector(
    (state) => state?.utility?.constants?.difficultyLevels
  );
  const {
    canViewAddOptions,
    canViewCloneQuestion,
    canDeleteQuestion,
    canEditQuestion,
    canViewToggleActiveQuestion,
    canViewDeleteQuestion,
    canViewEditQuestion,
    editQuestionMessage,
    deleteQuestionMessage,
    canToggleActiveQuestion,
    canAddOptions,
    canViewOptionsList,
    canCloneQuestion,
    toggleActiveQuestionMessage,
    addOptionsMessage,
    cloneQuestionMessage,
  } = permission;
  const questionOptions = question?.questionOptions;
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Accordion className="accordion" key={id} onChange={handleAccordionChange}>
      <AccordionSummary
        className="assessment-accordion-summary"
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box className="assessment-questions-head-container">
          <Box className="assessment-questions-head" sx={{ flexBasis: "83%" }}>
            <Typography
              variant="font_14"
              sx={{ textAlign: "justify", whiteSpace: "pre-line" }}
            >
              {question?.title}
            </Typography>
          </Box>
          <Box
            className="assessment-questions-head-right"
            sx={{ flexBasis: "17%" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {canViewToggleActiveQuestion && (
                <BootstrapTooltip
                  title={
                    !canToggleActiveQuestion
                      ? toggleActiveQuestionMessage
                      : question?.active
                      ? "Inactivate Assessment"
                      : "Activate Assessment"
                  }
                >
                  <Box>
                    <Switch
                      disabled={!canToggleActiveQuestion}
                      className="margin-right-2"
                      checked={question?.active || false}
                      size="small"
                      onChange={(event) =>
                        toggleQuestionModalOpen(
                          event,
                          question?.active,
                          question?.id
                        )
                      }
                      sx={{
                        margin: "0 0.5rem",
                        "& .MuiSwitch-thumb": {
                          width: 11,
                          height: 11,
                        },
                      }}
                    />
                  </Box>
                </BootstrapTooltip>
              )}
              {canViewEditQuestion && (
                <IconButtons
                  tooltip="Edit Option"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={edit_active_icon}
                  handleClick={(event) =>
                    questionEditModal(event, id, question)
                  }
                  classList="margin-right-1"
                  disabled={!canEditQuestion}
                  disabledImage={edit_disabled_icon}
                  disabledTooltip={
                    !canEditQuestion ? editQuestionMessage : null
                  }
                />
              )}
              {canViewCloneQuestion && (
                <IconButtons
                  tooltip="Clone question"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={clone_active_icon}
                  handleClick={(event) =>
                    cloneQuestionModalOpen(event, question)
                  }
                  id={id}
                  classList="margin-right-1"
                  disabledImage={clone_disabled_icon}
                  disabled={!canCloneQuestion}
                  disabledTooltip={
                    !canCloneQuestion ? cloneQuestionMessage : null
                  }
                />
              )}
              {canViewDeleteQuestion && (
                <IconButtons
                  tooltip="Delete Question"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={delete_active_icon}
                  handleClick={(event) => deleteModalOpenQuestion(event)}
                  id={id}
                  classList="margin-right-1"
                  disabled={!canDeleteQuestion}
                  disabledImage={delete_disabled_icon}
                  disabledTooltip={
                    !canDeleteQuestion ? deleteQuestionMessage : null
                  }
                />
              )}
              <IconButtons
                height={font_13}
                width={font_13}
                image={expanded ? up_arrow_icon : down_arrow_icon}
              />
            </Box>
          </Box>
        </Box>
        <Grid className="related-chip">
          <Box className="related-chip-info related-chip-left">
            <Box className="level-chip">
              <Typography variant="font_12" align="left">
                <Chip
                  className="same-size-chip"
                  label={
                    difficultyLevels?.filter(
                      (level) => level?.key === question?.difficultyLevel
                    )[0]?.value
                  }
                  variant={
                    difficultyLevels?.filter(
                      (level) => level?.key === question?.difficultyLevel
                    )[0]?.key
                  }
                />
              </Typography>
            </Box>
            <Box className="type-chip">
              <Typography variant="font_12" align="left">
                <Chip
                  className="same-size-type-chip"
                  label={
                    questionTypes?.filter(
                      (level) => level?.key === question?.type
                    )[0]?.value
                  }
                  variant="QUEST_TYPE"
                />
              </Typography>
            </Box>
          </Box>
          <Box className="related-chip-info related-chip-right">
            <Box className="more-info">
              <Typography variant="font_12" align="left">
                <Chip
                  className="more-info-chip"
                  label={question?.createdBy?.fullName}
                  variant="QUEST_TYPE"
                />
              </Typography>
            </Box>
            <Box className="more-info">
              <Typography variant="font_12" align="left">
                <Chip
                  className="more-info-chip"
                  label={dayjs(question?.dateCreated)?.format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                  variant="QUEST_TYPE"
                />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </AccordionSummary>

      <AccordionDetails className="accordion-details">
        <Box className="margin-bottom-5">
          <Grid container className="assessment-questions-container">
            <Grid item md={12} className="bmv-content">
              <Box className="assessment-info-box">
                <Typography variant="font_12" className="grey-text">
                  Description
                </Typography>
                <Typography
                  variant="font_13"
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {question?.description ? question?.description : "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {canViewOptionsList && questionOptions?.totalResults ? (
          <TableContainer className="table-container">
            <Table stickyHeader>
              <TableHead className="table-head-container">
                <TableRow className="table-head-row">
                  <TableCell
                    align="left"
                    className="padding-left-1"
                    sx={{ minWidth: "18rem", maxWidth: "18rem" }}
                  >
                    <Typography variant="font_13_bold">Option</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "8rem" }}>
                    <Typography variant="font_13_bold">Correct</Typography>
                  </TableCell>
                  {/* {isAdmin && ( */}
                  <TableCell align="center" sx={{ minWidth: "8rem" }}>
                    <Typography variant="font_13_bold">Actions</Typography>
                  </TableCell>
                  {/* )} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {questionOptions?.results?.map((option, index) => {
                  const optionPermission = option?.permission;
                  const {
                    canViewEditOption,
                    canEditOption,
                    canViewDeleteOption,
                    canDeleteOption,
                    deleteOptionMessage,
                    editOptionMessage,
                  } = optionPermission;

                  return (
                    !option?.deleted && (
                      <TableRow className="table-body-row" hover key={index}>
                        <TableCell align="left" className="padding-left-1">
                          <Typography
                            variant="font_12"
                            className={option?.deleted ? "room-deleted" : null}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {option?.optionText}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="font_12"
                            className={option.deleted ? "room-deleted" : null}
                          >
                            {option?.correct ? "yes" : "no"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {canViewEditOption && (
                            <IconButtons
                              tooltip="Edit Option"
                              width={font_13_icon}
                              height={font_13_icon}
                              image={edit_active_icon}
                              handleClick={() => optionEditModal(option)}
                              classList="margin-right-1"
                              disabled={!canEditOption}
                              disabledImage={edit_disabled_icon}
                              disabledTooltip={
                                !canEditOption ? editOptionMessage : null
                              }
                            />
                          )}
                          {canViewDeleteOption && (
                            <IconButtons
                              tooltip="Delete Option"
                              width={font_13_icon}
                              height={font_13_icon}
                              image={delete_active_icon}
                              handleClick={(event) =>
                                deleteModalOpenOption(event, option)
                              }
                              id={id}
                              disabled={!canDeleteOption}
                              disabledImage={delete_disabled_icon}
                              disabledTooltip={
                                !canDeleteOption ? deleteOptionMessage : null
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          question?.type !== "TEXT" && (
            <NoDataAvailable
              imgHeight={4}
              imgWidth={4.5}
              message="No Options Exist"
            />
          )
        )}
        {canViewAddOptions &&
          (question?.type === "MULTI_SELECT" ||
            question?.type === "SINGLE_SELECT") && (
            <BootstrapTooltip title={!canAddOptions ? addOptionsMessage : null}>
              <Box>
                <Button
                  sx={{ marginTop: "1rem" }}
                  fullWidth
                  className="block-dashed-btn assessment-questions-dashed-btn"
                  color="secondary"
                  image={black_plus_default_icon}
                  onClick={() => handleOptionModalOpen(id)}
                  startIcon={
                    <IconButtons
                      width={font_10}
                      height={font_10}
                      image={plus_active_icon}
                      disabledImage={plus_disabled_icon}
                      disabled={!canAddOptions}
                    />
                  }
                  disabled={!canAddOptions}
                >
                  <Typography variant="font_11_bold">Add Option</Typography>
                </Button>
              </Box>
            </BootstrapTooltip>
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AssessmentQuestionsCard;
