import React, { useEffect, useRef, useState } from "react";
import "./sessionView.scss";
import { Box, Grid, Link, Paper, Tab, Tabs, Typography } from "@mui/material";
import DetailsActionItems from "./DetailsTab/DetailsActionItems";
import AttendeesActionItems from "./AttendeesTab/AttendeesActionItems";
import ExerciseActionItems from "./ExerciseStatusTab/ExerciseActionItems";
import DetailsTab from "./DetailsTab/DetailsTab";
import AttendeesTab from "./AttendeesTab/AttendeesTab";
import ExerciseTab from "./ExerciseStatusTab/ExerciseTab";
import PresentersTab from "./PresentersTab/PresentersTab";
import SessionCreate from "../Sessions/SessionCreate";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  editSession,
  getAllModuleOptions,
  getAllTopicOptions,
} from "../../store/sessions";
import { useParams } from "react-router-dom";
import { getBoolean } from "../Sessions/sessionUtils";
import SendEmailModal from "../../components/SendEmailModal/SendEmailModal";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import {
  ROLE_TYPES,
  calculateFilterCount,
  checkDateTimeStatus,
  checkSpecialChar,
  hasAnyPermission,
  isOnlyTrueForPermissionKey,
  regex,
} from "../../utils/utils";
import { clearDetails, getDetails } from "../../store/SessionView/detailsTab";
import { useNavigate } from "react-router-dom/dist";
import {
  clearAttendeeTabFilters,
  countFilter,
  createAttendances,
  getAttendees,
  getFiltersForAttendeesTab,
  markAttendance,
} from "../../store/SessionView/attendeesTab";
import {
  clearExerciseFilters,
  countExerciseFilter,
  createAllExerciseStatuses,
  getExerciseStatus,
  getFiltersForExerciseStatusTab,
} from "../../store/SessionView/exerciseStatusTab";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import Constants, { EventStatus } from "../../constants/constants";
import ReportDownloadModal from "../../components/ReportDownloadModal/ReportDownloadModal";
import { backendBaseUrl } from "../../config/config";

const { SCHEDULED, COMPLETED } = Constants;
const { ONGOING } = EventStatus;

const initialSessionDetails = {
  sessionId: null,
  name: null,
  agenda: null,
  moduleId: null,
  topicId: null,
  containsExercise: false,
  maxExerciseScore: 0,
  startDate: null,
  duration: 0,
  associateExerciseIds: [],
  exerciseDueDate: null,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: "1.25rem 0.6rem 1rem 1.25rem",
            height: "calc(100vh - 16rem)",
            overflowY: "scroll",
          }}
          className="tab-view"
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RenderActionItems = ({
  renderValue,
  emailModalOpen,
  handleEditSession,
  handleAttendeesFilterOpen,
  handleSubmitAttendance,
  checkedRows,
  handleExerciseFilterOpen,
  handleCreateAttendences,
  handleCreateAllExercises,
  currentBootcamp,
  attendeesAvailable,
  creatingAttendance,
  isModuleCompleted,
  isHr,
  handleDownloadIconClick,
}) => {
  let componentToRender;

  switch (renderValue) {
    case 0:
      componentToRender = (
        <DetailsActionItems
          emailModalOpen={emailModalOpen}
          handleEditSession={handleEditSession}
          currentBootcamp={currentBootcamp}
          isModuleCompleted={isModuleCompleted}
        />
      );
      break;
    case 1:
      componentToRender = (
        <AttendeesActionItems
          handleAttendeesFilterOpen={handleAttendeesFilterOpen}
          handleSubmitAttendance={handleSubmitAttendance}
          checkedRows={checkedRows}
          handleCreateAttendences={handleCreateAttendences}
          attendeesAvailable={attendeesAvailable}
          creatingAttendance={creatingAttendance}
          currentBootcamp={currentBootcamp}
          isHr={isHr}
          handleDownloadIconClick={handleDownloadIconClick}
        />
      );
      break;
    case 2:
      componentToRender = (
        <ExerciseActionItems
          handleExerciseFilterOpen={handleExerciseFilterOpen}
          currentBootcamp={currentBootcamp}
          handleCreateAllExercises={handleCreateAllExercises}
          isHr={isHr}
          handleDownloadIconClick={handleDownloadIconClick}
        />
      );
      break;
    default:
      componentToRender = null;
  }

  return <>{componentToRender}</>;
};

const SessionView = () => {
  const navigate = useNavigate();
  const [showDate, setShowDate] = useState(false);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const security = useSelector((state) => state?.security);

  const roles = security?.roles;
  const isAdminOrSpoc =
    ROLE_TYPES.isAdmin(roles) || ROLE_TYPES.isCompetencySpoc(roles);
  const isHr = ROLE_TYPES?.isHr(roles);
  const isCompetencySPOC = ROLE_TYPES.isCompetencySpoc(roles);

  const params = useParams();
  const session = useSelector((state) => {
    return state?.sessionList;
  });
  const addSessionloading = session?.addSessionloading;
  const editSessionloading = session?.editSessionloading;

  const details = useSelector((state) => {
    return state?.detailsTab;
  });
  const module = details?.details?.data?.module;
  const isModuleCompleted = module?.status === COMPLETED;
  const topicIdToBeMatched = details?.details?.data?.topic?.id;
  const filterBootcamp = module?.bootcamps?.results?.find(
    (e) => e?.id === Number(params?.bootcampId)
  );
  const presenterStartDateTime =
    details?.details?.data?.presenters?.results?.find(
      (result) =>
        result?.leadPresenter?.employeeCode ===
        security?.loggedInUser?.employeeCode
    );

  const sessionIsScheduled = details?.details?.data?.permission
    ?.sessionPresenter
    ? checkDateTimeStatus(presenterStartDateTime?.startDate) === "future"
    : checkDateTimeStatus(
        details?.details?.data?.startDate,
        details?.details?.data?.endDate
      ) === "future";

  const isModuleScheduled = module?.status === SCHEDULED;
  const isBootcampScheduled = filterBootcamp?.status === SCHEDULED;
  const hasNoPermissions = details?.details?.data?.permission
    ? !hasAnyPermission(details.details.data.permission)
    : false;
  const disableTabs =
    isModuleScheduled ||
    isBootcampScheduled ||
    sessionIsScheduled ||
    (!isHr && hasNoPermissions);

  const isdetailLoaded = details?.details?.length !== 0;

  const exerciseStatusTab = useSelector((state) => {
    return state?.exerciseStatusTab;
  });

  const attendeesTab = useSelector((state) => {
    return state?.attendeesTab;
  });
  const attendeesList = attendeesTab?.attendees?.data?.results;
  const creatingAttendance = attendeesTab?.createAttendanceLoading;
  ///////////////////////////////////////////////////////////////
  //////////////// handle Breadcrumb /////////////////////

  const idTopic = +params?.topicId || false;
  const sessionName = details?.details?.data?.name;
  const loader = details?.loading;
  const sessionViewId = params?.sessionId || false;
  const moduleBootcamps = details?.details?.data?.module?.bootcamps?.results;
  const bootcampId = params?.bootcampId;
  const [bootcampName, setBootcampName] = useState(null);
  const isFirstRun = useRef(true);
  const isFirstRender = useRef(true);
  let matchedBootcamp;
  useEffect(() => {
    if (!isFirstRun.current) {
      matchedBootcamp = moduleBootcamps?.find(
        (item) => item.id === +bootcampId
      );
      if (matchedBootcamp) {
        setBootcampName(matchedBootcamp?.name);
      } else if (moduleBootcamps && !idTopic) {
        ////// incorrect bootcamp ID //////
        navigate("/error", { replace: true });
      }
    }
    isFirstRun.current = false;
  }, [moduleBootcamps]);

  const breadcrumbs = idTopic
    ? [
        { label: "Topics", url: "/topic", isTopic: true, topicId: idTopic },
        {
          label: "Sessions",
          url: `/topic/${idTopic}/sessions` || "/",
        },
        {
          label: sessionName ? sessionName : "Session View",
          isSessionView: true,
          sessionViewId: sessionViewId,
        },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampName ? bootcampName : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: module?.name ? module?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${module?.id}` || "/",
        },
        {
          label: "Sessions",
          url: `/bootcamp/${bootcampId}/module/${module?.id}/sessions` || "/",
        },
        {
          label: sessionName ? sessionName : "Session View",
          isSessionView: true,
          sessionViewId: sessionViewId,
        },
      ];
  /////////////////////////////////////////////////////////////////
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs/plugin/timezone");
  ///////////////////////////////////////////////////////////////
  //////////////// handle SEnd Email Modal /////////////////////
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openEmailModuleId, setOpenEmailModuleId] = useState();
  const [openEmailSessionId, setOpenEmailSessionId] = useState();
  const [openEmailBatches, setOpenEmailBatches] = useState([]);

  const handleOpenSendEmailModal = (data, moduleBatches) => {
    const batches = moduleBatches || [];
    const moduleId = data?.details?.data?.module?.id;
    setOpenEmailModuleId(moduleId);
    setOpenEmailSessionId(data?.details?.data?.id);
    setOpenEmailBatches(batches);
    setOpenEmailModal(true);
  };

  const handleCloseSendEmailModal = () => {
    setOpenEmailModal(false);
    setOpenEmailModuleId();
    setOpenEmailSessionId();
    setOpenEmailBatches([]);
  };
  ///////////////////////////////////////////////////////////////

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tz = "Asia/Kolkata";
  const dispatch = useDispatch();

  /////////// Create Session ////////////////////////////////////
  const [openCreateSession, setOpenCreateSession] = useState(false);
  const handleCreateSessionClose = () => {
    setOpenCreateSession(false);
    setIsEditSession(false);
    setSessionDetails(initialSessionDetails);
    setShowDate(false);
    setErrors(initialErrorState);
    setApiError(false);
  };

  ///////////////////////////////////////////////////////////////
  ///////////////////// Edit Session///////////////////////////////
  const [isEditSession, setIsEditSession] = useState(false);
  const allModuleOptions = session?.getAllModuleOptions?.data?.results;
  const allTopicOptions = session?.getAllTopicOptions?.data?.results;
  const exerciseOptions = session?.exerciseOptions?.data?.results;
  const containsExerciseOPtions = getBoolean;
  const [isSessionSpoc, setIsSessionSpoc] = useState(false);
  const handleEditSession = (session, isSessionSpoc) => {
    setSessionLengthError(false);
    setSessionSpecialChar(false);
    setErrors(initialErrorState);
    setIsEditSession(true);
    setIsSessionSpoc(isSessionSpoc);
    setSessionDetails({
      sessionId: params?.sessionId,
      name: session?.data?.name,
      agenda: session?.data?.agenda,
      moduleId: session?.data?.module,
      topicId: session?.data?.topic,
      containsExercise: session?.data?.containsExercise,
      startDate: dayjs(session?.data?.startDate),
      endDate: dayjs(session?.data?.endDate),
      duration: dayjs(session?.data?.endDate).diff(
        dayjs(session?.data?.startDate),
        "hour"
      ),
      ...(session?.data?.containsExercise && {
        maxExerciseScore: Number(session?.data?.maxExerciseScore),
        exerciseDueDate: dayjs(
          session?.data?.exerciseSubmissionDueDate
        ).isValid()
          ? dayjs(session?.data?.exerciseSubmissionDueDate)
          : null,
      }),
    });
    setOpenCreateSession(true);
  };
  /////////////////////////////////////////////////////////////////
  ////////////////////// handle Session Submit  //////////////////
  const [sessionDetails, setSessionDetails] = useState(initialSessionDetails);
  const [sessionSpecialChar, setSessionSpecialChar] = useState(false);
  const [sessionLengthError, setSessionLengthError] = useState(false);
  const [seatAlphabetError1, setSeatAlphabetError1] = useState(false);
  const [seatAlphabetError2, setSeatAlphabetError2] = useState(false);

  const handleSessionDetailsChange = (name, value) => {
    setApiError(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });

    if (name === "duration" || name === "maxExerciseScore") {
      if (name === "duration") {
        setSeatAlphabetError1(false);
      }
      if (name === "maxExerciseScore") {
        setSeatAlphabetError2(false);
      }

      if ((value === "" || regex.test(value)) && Number(value) >= 0) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            [name]: value === "" ? "" : Number(value),
          };
        });
      } else if (!Number(value)) {
        if (name === "duration") {
          setSeatAlphabetError1(true);
        }
        if (name === "maxExerciseScore") {
          setSeatAlphabetError2(true);
        }
      }
    } else if (name === "name") {
      if (value?.trim().length <= 50) {
        setSessionSpecialChar(checkSpecialChar(value));
        setSessionLengthError(false);
        setSessionDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setSessionLengthError(true);
      }
    } else if (name === "containsExercise") {
      setSessionDetails((prev) => {
        return {
          ...prev,
          [name]: value,
          maxExerciseScore: sessionDetails?.maxExerciseScore
            ? sessionDetails?.maxExerciseScore
            : 0,
        };
      });
      if (
        !sessionDetails?.exerciseDueDate &&
        value === true &&
        sessionDetails?.startDate
      ) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            exerciseDueDate: dayjs(sessionDetails?.startDate)
              ?.set("hour", 22)
              ?.set("minute", 0),
          };
        });
      }
    } else if (name === "startDate") {
      setSessionDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      if (!dayjs(sessionDetails?.exerciseDueDate).isValid()) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            exerciseDueDate: value,
          };
        });
      } else {
        setSessionDetails((prev) => {
          return {
            ...prev,
            exerciseDueDate: !dayjs(value).isSame(
              dayjs(sessionDetails?.exerciseDueDate),
              "date"
            )
              ? dayjs(value)?.set("hour", 22)?.set("minute", 0)
              : sessionDetails?.exerciseDueDate,
          };
        });
      }
    } else {
      setSessionDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleSubmitSession = async () => {
    let apiValue;
    const startDateFormat = dayjs(sessionDetails?.startDate)?.tz(tz)?.format();
    const endDateFormat = dayjs(sessionDetails?.startDate)
      ?.tz(tz)
      ?.add(sessionDetails?.duration, "hour")
      ?.format();

    const exerciseDueDateFormat = dayjs(sessionDetails?.exerciseDueDate)
      ?.tz(tz)
      ?.format();

    const data = {
      sessionId: sessionDetails?.sessionId,
      name: sessionDetails?.name,
      agenda: sessionDetails?.agenda,
      containsExercise: sessionDetails?.containsExercise,
      moduleId: sessionDetails?.moduleId?.id || null,
      topicId: sessionDetails?.topicId?.id || null,
      startDate: startDateFormat !== "Invalid Date" ? startDateFormat : null,
      endDate: endDateFormat !== "Invalid Date" ? endDateFormat : null,
      ...(sessionDetails?.containsExercise && {
        maxExerciseScore: Number(sessionDetails?.maxExerciseScore),
        exerciseDueDate:
          exerciseDueDateFormat !== "Invalid Date"
            ? exerciseDueDateFormat
            : null,
      }),
      duration: sessionDetails?.duration,
    };

    if (validateFields(sessionDetails)) {
      if (isEditSession) {
        apiValue = await dispatch(editSession(data));
      }

      if (apiValue?.error?.message === "Rejected") {
        setApiError(true);
      } else {
        handleCreateSessionClose();
        dispatch(getDetails({ sessionId: params?.sessionId }));
      }
    }
  };

  //////////////////// validate session /////////////////////////
  const initialErrorState = {
    sessionId: null,
    name: null,
    agenda: null,
    moduleId: null,
    topicId: null,
    containsExercise: null,
    maxExerciseScore: null,
    startDate: null,
    endDate: null,
    associateExerciseIds: [],
    exerciseDueDate: null,
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [apiError, setApiError] = useState(false);

  const validateFields = (sessionDetails) => {
    const {
      name,
      moduleId,
      topicId,
      containsExercise,
      startDate,
      endDate,
      maxExerciseScore,
      duration,
      exerciseDueDate,
    } = sessionDetails;
    const isOnGoing = checkDateTimeStatus(startDate, endDate) === ONGOING;
    const newErrors = {
      name: name ? "" : "Name is required",
      moduleId: moduleId ? "" : "Module is required",
      topicId: topicId ? "" : "Topic is required",
      containsExercise:
        containsExercise !== null ? "" : "Contains Exercise is required",
      startDate: startDate
        ? dayjs(startDate).isBefore(dayjs()) && (!isEditSession || !isOnGoing)
          ? "Start date and time must be in the future"
          : ""
        : "Start Date is required",
      endDate: endDate ? "" : "End Date is required",
      maxExerciseScore: containsExercise
        ? maxExerciseScore === ""
          ? "Max exercise score is required"
          : maxExerciseScore <= 0
          ? "Must be greater than 0"
          : ""
        : "",
      exerciseDueDate: containsExercise
        ? exerciseDueDate
          ? !dayjs(exerciseDueDate)?.isAfter(dayjs(startDate)) ||
            dayjs(exerciseDueDate)?.isBefore(dayjs())
            ? "Exercise Due Date must be after Start Date"
            : ""
          : "Exercise Due Date is required"
        : "",
      duration:
        duration === ""
          ? "Duration is required"
          : duration <= 0
          ? "Must be greater than 0"
          : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (!isFirstRender?.current && module?.permission?.spoc) {
      dispatch(getAllModuleOptions());
      dispatch(getAllTopicOptions());
    }
    return () => {
      dispatch(clearAttendeeTabFilters());
      dispatch(clearExerciseFilters());
    };
  }, [dispatch, module]);

  useEffect(() => {
    if (
      !isFirstRender.current &&
      idTopic &&
      topicIdToBeMatched &&
      topicIdToBeMatched !== idTopic
    ) {
      navigate("/error", {
        replace: true,
        state: { isAccessDenied: false },
      });
    }
    isFirstRender.current = false;
  }, [topicIdToBeMatched]);

  useEffect(() => {
    return () => {
      dispatch(clearDetails());
    };
  }, []);

  /////////////////////////////////////////////////////////////
  //////////////////// Attendees Filter Open /////////////////
  const param = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const handleAttendeesFilterOpen = () => {
    setFilterOpen(true);
    if (!attendeesTab?.filtersForAttendeesTab?.data?.bootcamps?.results) {
      dispatch(getFiltersForAttendeesTab({ sessionId: params?.sessionId }));
    }
  };
  const handleAttendeesFilterClose = () => {
    setFilterOpen(false);
  };

  /////////////////////////////////////////////////////////////////
  //////////////////// Exercise Filter Open /////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const permission = details?.details?.data?.permission;
  const isMentor = permission?.mentor;
  const hasRestrictedPermission = (permissions) => {
    const isRestricted =
      permissions?.mentor ||
      permissions?.attendee ||
      permissions?.exerciseReviewer;
    const isNotSpocOrPresenter =
      !permissions?.spoc && !permissions?.sessionPresenter;
    return isRestricted && isNotSpocOrPresenter;
  };
  const isRestrictedUser = hasRestrictedPermission(permission);
  const initialFilterAttendee = {
    attendeeNameOrEmail: "",
    mentorNameOrEmail: "",
    competencyIds: [],
    bootcampIds: null,
    batchIds: [],
    status: null,
    showAttendees: false,
    showMentees: false,
    showReviewees: false,
  };
  const [filterAttendee, setFilterAttendee] = useState(initialFilterAttendee);
  const [initialFilterValues, setInitialFilterValues] = useState(
    initialFilterAttendee
  );
  const [exerciseFilterOpen, setExerciseFilterOpen] = useState(false);
  const handleExerciseFilterOpen = () => {
    setExerciseFilterOpen(true);
    if (!exerciseStatusTab?.filtersForExerciseTab?.data?.bootcamps?.results) {
      dispatch(
        getFiltersForExerciseStatusTab({ sessionId: params?.sessionId })
      );
    }
  };
  const handleExerciseFilterClose = () => {
    setExerciseFilterOpen(false);
  };

  /////////////////////////////////////////////////////////////////
  /////////////////////// handle checkbox attendee attendance ///////////////////////////////////////////////////////
  const [checkedRows, setCheckedRows] = useState([]);
  const [isFilterData, setFilterData] = useState(null);

  const handleCheckboxChange = (attendee, isChecked, filteredData) => {
    setFilterData(filteredData);
    if (isChecked) {
      setCheckedRows((prev) => [...prev, attendee]);
    } else {
      setCheckedRows((prev) =>
        prev.filter((prevAtt) => prevAtt?.id !== attendee?.id)
      );
    }
  };

  const handleSubmitAttendance = async (value) => {
    const checkedAttendees = checkedRows?.map((e) => {
      return {
        attendeeId: e?.attendee?.id,
        status: value,
      };
    });

    if (checkedRows?.length) {
      await dispatch(
        markAttendance({
          sessionId: Number(params?.sessionId),
          attendeeAttendances: checkedAttendees,
        })
      );
      setCheckedRows([]);
      const data = {
        sessionId: params?.sessionId,
        status: filterAttendee?.status,
        attendeeNameOrEmail: filterAttendee?.attendeeNameOrEmail.trim(),
        mentorNameOrEmail: filterAttendee?.mentorNameOrEmail.trim(),
        competencyIds: filterAttendee?.competencyIds?.map((e) => e?.id),
        bootcampIds: filterAttendee?.bootcampIds?.id,
        batchIds: filterAttendee?.batchIds?.map((e) => e?.id),
        showMentees: filterAttendee?.showMentees,
        showReviewees: filterAttendee?.showReviewees,
        showAttendees: filterAttendee?.showAttendees,
        sortBy: isFilterData?.sortBy,
        sortOrder: isFilterData?.orderBy,
        offset: isFilterData?.offset,
        bootcampId,
      };
      await dispatch(getAttendees(data));
    }
  };

  /////////////////////////////////////////////////////////////////
  /////////////////////// handle create attendance ///////////////////////////////////////////////////////
  const handleCreateAttendences = async () => {
    await dispatch(createAttendances({ sessionId: param?.sessionId }));
    dispatch(clearAttendeeTabFilters());
    const bootcampIds = initialFilterValues?.bootcampIds;

    const data = {
      ...initialFilterValues,
      sessionId: params?.sessionId,
      ...(!(isHr || permission?.attendee)
        ? { bootcampIds: bootcampIds?.id }
        : { bootcampIds: null }),
      sortBy: "attendeeName",
      sortOrder: "ASC",
      offset: 0,
      bootcampId,
    };
    setFilterAttendee({
      ...initialFilterValues,
    });
    setCurrentPage(1);
    await dispatch(
      countFilter(!isHr ? calculateFilterCount(initialFilterValues) : 0)
    );
    await dispatch(getAttendees(data));
  };
  /////////////////////////////////////////////////////////////////
  /////////////////////// handle create exercise ///////////////////////////////////////////////////////

  const initialFilterExercise = {
    attendeeNameOrEmail: "",
    mentorNameOrEmail: "",
    reviewerNameOrEmail: "",
    competencyIds: [],
    bootcampIds: null,
    batchIds: [],
    status: null,
    showAttendees: false,
    showMentees: false,
    showReviewees: false,
  };
  const [initialFilterApplied, setInitialFilterApplied] = useState(false);
  const [filterExercise, setFilterExercise] = useState(initialFilterExercise);
  const [initialExerciseFilterValues, setInitialExerciseFilterValues] =
    useState(initialFilterExercise);
  const [exerciseCurrentPage, setExerciseCurrentPage] = useState(1);

  const handleCreateAllExercises = async () => {
    await dispatch(createAllExerciseStatuses({ sessionId: params?.sessionId }));
    await dispatch(clearExerciseFilters());
    const fetchData = async () => {
      if (initialFilterApplied) {
        const bootcampIds = filterExercise?.bootcampIds;
        await setFilterExercise({
          ...initialFilterValues,
          ...(!(isHr || permission?.attendee)
            ? { bootcampIds: bootcampIds }
            : { bootcampIds: null }),
        });

        await dispatch(
          getExerciseStatus({
            sessionId: params?.sessionId,
            offset: 0,
            sortBy: "attendeeName",
            sortOrder: "ASC",
            ...(!permission?.attendee
              ? {
                  bootcampIds: [bootcampIds.id],
                }
              : null),
            ...(permission?.mentor &&
              !permission?.spoc &&
              !isHr && { showMentees: filterExercise?.showMentees }),
            ...(permission?.exerciseReviewer &&
              !permission?.spoc &&
              !isHr && { showReviewees: filterExercise?.showReviewees }),
            ...(permission?.sessionPresenter &&
              !permission?.spoc &&
              !isHr && { showAttendees: filterExercise?.showAttendees }),
            bootcampId: bootcampId,
          })
        );
        setExerciseCurrentPage(1);
        await dispatch(
          countExerciseFilter(
            !isHr ? calculateFilterCount(initialExerciseFilterValues) : 1
          )
        );
      }
    };
    fetchData();
  };
  const getTabTooltip = (tabIndex) => {
    if (isBootcampScheduled) return "Bootcamp is scheduled";
    if (isModuleScheduled) return "Module is scheduled";
    if (sessionIsScheduled) return "Session is scheduled";
    if (!isHr && !hasAnyPermission(details?.details?.data?.permission))
      return "Permission required";
    if (tabIndex === 2 && !details?.details?.data?.containsExercise)
      return "Contains no exercise";
    return null;
  };

  const [reportDownloadOpen, setReportDownloadOpen] = useState(false);
  const [reportDownloadData, setReportDownloadData] = useState({});

  const downloadFile = (data) => {
    let fileUrl = `${backendBaseUrl}/api/v1/${
      value === 1 ? `sessionAttendance` : value == 2 ? `sessionExercise` : ``
    }/report?sessionId=${data?.sessionId}`;
    if (data?.bootcamps?.length) {
      fileUrl = fileUrl + `&bootcampIds=${data?.bootcamps?.map((it) => it?.id)}`;
    }
    window.open(fileUrl, "_blank");
  };
  const handleDownloadIconClick = () => {
    if (isHr) {
      setReportDownloadOpen(true);
      setReportDownloadData({
        sessionId: params?.sessionId,
        bootcamps: moduleBootcamps,
      });
    } else {
      downloadFile({ sessionId: params?.sessionId });
    }
  };

  const handlReportBootcampChange = (value) => {
    setReportDownloadData({
      bootcamps: value,
    });
  };
  const handlReportBootcampModalClose = () => {
    setReportDownloadOpen(false);
    setReportDownloadData({});
  };

  const handlReportBootcampSubmit = () => {
    downloadFile(reportDownloadData);
    handlReportBootcampModalClose();
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Grid container className="main-container">
        {isdetailLoaded && <BreadCrumb breadcrumbs={breadcrumbs} />}
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">
            {isdetailLoaded && sessionName}
          </Typography>
          <Box className="header-right-icons">
            <RenderActionItems
              renderValue={value}
              handleEditSession={handleEditSession}
              emailModalOpen={handleOpenSendEmailModal}
              handleAttendeesFilterOpen={handleAttendeesFilterOpen}
              handleSubmitAttendance={handleSubmitAttendance}
              checkedRows={checkedRows}
              handleExerciseFilterOpen={handleExerciseFilterOpen}
              handleCreateAttendences={handleCreateAttendences}
              isHr={isHr}
              currentBootcamp={filterBootcamp}
              attendeesAvailable={attendeesList?.length}
              creatingAttendance={creatingAttendance}
              handleCreateAllExercises={handleCreateAllExercises}
              isModuleCompleted={isModuleCompleted}
              handleDownloadIconClick={handleDownloadIconClick}
            />
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Paper className="tabs-container" elevation={0}>
            <Tabs
              className="tab-head-container"
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
            >
              <Tab
                label="Details"
                className="tabs-header-style"
                {...a11yProps(0)}
              />
              {disableTabs ? (
                <BootstrapTooltip title={getTabTooltip(1)}>
                  <Box component="span">
                    <Tab
                      label="Attendances"
                      className="tabs-header-style"
                      disabled={disableTabs}
                      {...a11yProps(1)}
                    />
                  </Box>
                </BootstrapTooltip>
              ) : (
                <Tab
                  label="Attendances"
                  className="tabs-header-style"
                  {...a11yProps(1)}
                />
              )}
              {(details?.details?.data?.containsExercise !== undefined
                ? !details?.details?.data?.containsExercise
                : false) || disableTabs ? (
                <BootstrapTooltip title={getTabTooltip(2)}>
                  <Box component="span">
                    <Tab
                      label="Exercise Status"
                      className="tabs-header-style"
                      disabled={
                        !details?.details?.data?.containsExercise || disableTabs
                      }
                      {...a11yProps(2)}
                    />
                  </Box>
                </BootstrapTooltip>
              ) : (
                <Tab
                  label="Exercise Status"
                  className="tabs-header-style"
                  {...a11yProps(2)}
                />
              )}
              <Tab
                label="Presenters"
                className="tabs-header-style"
                {...a11yProps(3)}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <DetailsTab matchedBootcamp={matchedBootcamp} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <AttendeesTab
                filterOpen={filterOpen}
                handleAttendeesFilterClose={handleAttendeesFilterClose}
                handleCheckboxChange={handleCheckboxChange}
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                isCompetencySPOC={isCompetencySPOC}
                isHr={isHr}
                setFilterData={setFilterData}
                isMentor={isMentor}
                isRestrictedUser={isRestrictedUser}
                setFilterAttendee={setFilterAttendee}
                filterAttendee={filterAttendee}
                setInitialFilterValues={setInitialFilterValues}
                initialFilterValues={initialFilterValues}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentBootcamp={filterBootcamp}
                initialFilterAttendee={initialFilterAttendee}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ExerciseTab
                filterOpen={exerciseFilterOpen}
                handleExerciseFilterClose={handleExerciseFilterClose}
                isCompetencySPOC={isCompetencySPOC}
                isHr={isHr}
                filterExercise={filterExercise}
                setFilterExercise={setFilterExercise}
                initialFilterValues={initialExerciseFilterValues}
                setInitialFilterValues={setInitialExerciseFilterValues}
                initialFilterApplied={initialFilterApplied}
                setInitialFilterApplied={setInitialFilterApplied}
                currentPage={exerciseCurrentPage}
                setCurrentPage={setExerciseCurrentPage}
                initialFilterExercise={initialFilterExercise}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <PresentersTab currentBootcamp={filterBootcamp} />
            </CustomTabPanel>
          </Paper>
        </Grid>
      </Grid>

      <SessionCreate
        open={openCreateSession}
        onClose={handleCreateSessionClose}
        sessionDetails={sessionDetails}
        handleSessionDetailsChange={handleSessionDetailsChange}
        allModuleOptions={allModuleOptions}
        allTopicOptions={allTopicOptions}
        containsExerciseOPtions={containsExerciseOPtions}
        exerciseOptions={exerciseOptions}
        handleSubmitSession={handleSubmitSession}
        isEditSession={isEditSession}
        loader={addSessionloading || editSessionloading}
        errors={errors}
        module={module}
        showDate={showDate}
        setShowDate={setShowDate}
        apiError={apiError}
        isSessionSpoc={isSessionSpoc}
        sessionSpecialChar={sessionSpecialChar}
        sessionLengthError={sessionLengthError}
        numberInputError1={seatAlphabetError1}
        numberInputError2={seatAlphabetError2}
      />

      <SendEmailModal
        open={openEmailModal}
        handleClose={handleCloseSendEmailModal}
        moduleId={openEmailModuleId}
        sessionId={openEmailSessionId}
        batches={openEmailBatches}
      />

      <ReportDownloadModal
        open={reportDownloadOpen}
        handleClose={handlReportBootcampModalClose}
        bootcampList={moduleBootcamps}
        handleBootcampChange={handlReportBootcampChange}
        selectedBootcamps={reportDownloadData?.bootcamps || []}
        handleDownloadFile={handlReportBootcampSubmit}
      />
    </>
  );
};

export default SessionView;
