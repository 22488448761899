import React from "react";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  edit_active_icon,
  edit_disabled_icon,
  list_eval_disabled_icon,
  list_eval_icon,
} from "../../constants/icons";
import { Box, Switch } from "@mui/material";
import { font_13_icon, font_15 } from "../../utils/utils";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

const AssessmentViewActionItems = ({
  assessmentId,
  isAssessmentActive,
  permissions,
  handleActivateInactivateAssessment,
  handleEditAssessment,
  handleViewEvaluations,
}) => {
  const {
    canViewToggleActive,
    canToggleActive,
    canViewEditAssessment,
    canEditAssessment,
    toggleActiveMessage,
    editAssessmentMessage,
    canViewListEvaluations,
    canListEvaluations,
    listEvaluationsMessage,
  } = permissions || {};

  return (
    <>
      {canViewToggleActive && (
        <BootstrapTooltip
          title={
            !canToggleActive
              ? toggleActiveMessage
              : isAssessmentActive
              ? "Inactivate Assessment"
              : "Activate Assessment"
          }
        >
          <Box>
            <Switch
              className="margin-right-2"
              checked={isAssessmentActive || false}
              size="small"
              onChange={(event) =>
                handleActivateInactivateAssessment(
                  event,
                  assessmentId,
                  isAssessmentActive
                )
              }
              sx={{
                margin: 0,
                "& .MuiSwitch-thumb": {
                  width: 10,
                  height: 10,
                },
              }}
              disabled={!canToggleActive}
            />
          </Box>
        </BootstrapTooltip>
      )}
      {canViewEditAssessment && (
        <IconButtons
          tooltip="Edit Assessment"
          width={font_13_icon}
          height={font_13_icon}
          image={edit_active_icon}
          handleClick={(event) => handleEditAssessment(event, assessmentId)}
          classList="margin-right-1"
          disabled={!canEditAssessment}
          disabledImage={edit_disabled_icon}
          disabledTooltip={!canEditAssessment ? editAssessmentMessage : null}
        />
      )}
      {canViewListEvaluations && (
        <IconButtons
          tooltip="List Evaluation"
          width={font_15}
          height={font_15}
          image={list_eval_icon}
          handleClick={(event) => handleViewEvaluations(event, assessmentId)}
          id={assessmentId}
          classList="margin-right-1"
          disabled={!canListEvaluations}
          disabledImage={list_eval_disabled_icon}
          disabledTooltip={!canListEvaluations ? listEvaluationsMessage : null}
        />
      )}
    </>
  );
};

export default AssessmentViewActionItems;
