import React, { useEffect, useState } from "react";
import "./createAssessment.scss";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  allValuesDisabled,
  calculateAssessmentCounts,
  checkSpecialChar,
  createFor,
  eventDateRange,
  regex,
  shouldDisableWeekends,
} from "../../utils/utils";
import ReactQuill from "react-quill";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";
import dayjs from "dayjs";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import QuestionPoolTable from "../../components/QuestionPool/QuestionPoolTable";
import QuestionTypeTable from "../../components/QuestionType/QuestionTypeTable";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addAssessment,
  clearAssessmentInfo,
  clearBootcampInfo,
  editAssessment,
  getAssessmentById,
  getBootcampByIdOnAssessment,
  getModuleByIdOnAssessment,
  getModulesList,
  getSessionsList,
} from "../../store/createAssessment";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const initialAssessmentDetails = {
  title: "",
  type: null,
  description: "",
  instruction: "",
  activeFromDate: null,
  activeToDate: null,
  durationInMinutes: 15,
  moduleId: null,
  sessionIds: [],
  markPerEasyQuestion: 1,
  markPerMediumQuestion: 2,
  markPerHardQuestion: 3,
  topicQuestions: null,
};

const hasDetailsChanged = (initialDetails, currentDetails) => {
  const fieldsToCheck = Object.keys(initialDetails);
  return fieldsToCheck.some((field) => {
    if (field === "sessionIds") {
      if (initialDetails[field]?.length !== currentDetails[field]?.length)
        return true;
      const initialIds = initialDetails[field]?.map((item) => item?.id).sort();
      const currentIds = currentDetails[field]?.map((item) => item?.id).sort();
      return !initialIds.every((id, index) => id === currentIds[index]);
    } else if (field === "topicQuestions") {
      const sortedInitialTopicQuestions = initialDetails[field]?.sort(
        (a, b) => a.topicId - b.topicId
      );
      const sortedCurrentTopicQuestions = currentDetails[field]?.sort(
        (a, b) => a.topicId - b.topicId
      );
      return (
        JSON.stringify(sortedInitialTopicQuestions) !==
        JSON.stringify(sortedCurrentTopicQuestions)
      );
    }
    return JSON.stringify(initialDetails) !== JSON.stringify(currentDetails);
  });
};

const CreateAssessment = () => {
  const location = useLocation();
  const { assessmentId, viaAssessmentView, disabledSessionsId } =
    location?.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const viaModule = !!params.moduleId;
  const moduleId = +params.moduleId;
  const bootcampId = +params.bootcampId;

  const [assessmentDetails, setAssessmentDetails] = useState(
    initialAssessmentDetails
  );
  const [sessionSpecialChar, setSessionSpecialChar] = useState(false);
  const [sessionLengthError, setSessionLengthError] = useState(false);

  const assessmentTypes = useSelector(
    (state) => state?.utility?.constants?.assessmentTypes
  );
  const assessmentData = useSelector((state) => {
    return state?.createAssessment;
  });
  const addAssessmentLoader = assessmentData?.addAssessmentLoader;
  const editAssessmentLoader = assessmentData?.editAssessmentLoader;
  const sessionsList = assessmentData.sessionsListData?.data?.sessions?.results;
  const sessionsListLoading = assessmentData.sessionsListLoader;
  const modulesListLoading = assessmentData.modulesListLoader;
  const bootcampData = assessmentData?.getBootcampDataById?.data;
  const bootcampEndDate = bootcampData?.endDate;
  const moduleData = assessmentData?.getModuleDataById?.data;

  const assessmentInfo = assessmentData?.assessmentInfo?.data;
  const assessmentInfoLoading = assessmentData?.getAssessmentLoader;

  //////////////
  const initialErrorState = {
    title: null,
    type: null,
    description: null,
    instruction: null,
    activeFromDate: null,
    activeToDate: null,
    durationInMinutes: null,
    moduleId: null,
    sessionIds: null,
    markPerEasyQuestion: null,
    markPerMediumQuestion: null,
    markPerHardQuestion: null,
    topicQuestions: null,
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [durationError, setDurationError] = useState(false);
  const [easyError, setEasyError] = useState(false);
  const [mediumError, setMediumError] = useState(false);
  const [HardError, setHardError] = useState(false);
  const [apiError, setApiError] = useState(false);

  const validateFields = () => {
    const {
      title,
      type,
      description,
      instruction,
      activeFromDate,
      activeToDate,
      durationInMinutes,
      moduleId,
      sessionIds,
      markPerEasyQuestion,
      markPerMediumQuestion,
      markPerHardQuestion,
      topicQuestions,
    } = assessmentDetails;
    const validateNumberFields = (label, fieldName) => {
      if (label === "" || label === null) {
        return `${fieldName}`;
      }
      if (label <= 0) {
        return "Must be greater than 0";
      }
      return "";
    };
    // Check if all counts in a topic are either null or 0
    const newErrors = {
      title: title ? "" : "Title is required",
      type: type ? "" : "Type is required",
      description: description ? "" : "Description is required",
      instruction: instruction ? "" : "Instruction is required",
      activeFromDate: activeFromDate ? "" : "Start Date is required",
      activeToDate: activeToDate
        ? !dayjs(activeToDate)?.isAfter(dayjs(activeFromDate)) ||
          dayjs(activeToDate)?.isBefore(dayjs())
          ? "End Date must be after Start Date"
          : ""
        : "End Date is required",
      durationInMinutes:
        durationInMinutes === "" || durationInMinutes === null
          ? "Duration is required"
          : durationInMinutes < 15
          ? "Must be at least 15 minutes"
          : durationInMinutes > 90
          ? "Must be no more than 90 minutes"
          : "",
      markPerEasyQuestion: validateNumberFields(
        markPerEasyQuestion,
        "Score is required"
      ),
      markPerMediumQuestion: validateNumberFields(
        markPerMediumQuestion,
        "Score is required"
      ),
      markPerHardQuestion: validateNumberFields(
        markPerHardQuestion,
        "Score is required"
      ),
      markHierarchy:
        markPerEasyQuestion && markPerMediumQuestion && markPerHardQuestion
          ? markPerEasyQuestion >= markPerMediumQuestion
            ? "Easy mark must be less than Medium mark"
            : markPerMediumQuestion >= markPerHardQuestion
            ? "Medium mark must be less than Hard mark"
            : ""
          : "",
      moduleId: viaModule && !moduleId ? "module is required" : "",
      sessionIds:
        sessionIds && sessionIds.length > 0 ? "" : "session is required",
    };
    const topicErrors = topicQuestions?.map((topic, index) => {
      const { easyQuestionsCount, mediumQuestionsCount, hardQuestionsCount } =
        topic;
      let topicError = "";
      if (
        (easyQuestionsCount === null ||
          easyQuestionsCount === undefined ||
          easyQuestionsCount <= 0) &&
        (mediumQuestionsCount === null ||
          mediumQuestionsCount === undefined ||
          mediumQuestionsCount <= 0) &&
        (hardQuestionsCount === null ||
          hardQuestionsCount === undefined ||
          hardQuestionsCount <= 0)
      ) {
        topicError = `At least one question count is required`;
      }

      return topicError;
    });

    newErrors.topicQuestions = topicErrors?.some((error) => error !== "")
      ? topicErrors
      : "";

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  const errorSetters = {
    durationInMinutes: setDurationError,
    markPerHardQuestion: setHardError,
    markPerMediumQuestion: setMediumError,
    markPerEasyQuestion: setEasyError,
  };
  const handleAssessmentDetailsChange = async (
    name,
    value,
    topicId = null,
    assessmentQuestions = null
  ) => {
    setApiError(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
    if (name === "sessionIds") {
      let sessionIds = value;
      if (assessmentId && !value?.length) {
        const toRetain = assessmentDetails?.sessionIds?.filter((item) =>
          disabledSessionsId?.includes(item?.id)
        );
        sessionIds = [...toRetain];
      }
      setAssessmentDetails((prev) => {
        return {
          ...prev,
          [name]: sessionIds,
        };
      });
    } else if (name === "title") {
      if (value?.trim().length <= 50) {
        setSessionSpecialChar(checkSpecialChar(value));
        setSessionLengthError(false);
        setAssessmentDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setSessionLengthError(true);
      }
    } else if (name === "activeFromDate") {
      if (!assessmentDetails?.activeFromDate) {
        setAssessmentDetails((prev) => {
          return {
            ...prev,
            activeFromDate: dayjs(value)?.set("hour", 10)?.set("minute", 0),
          };
        });
      } else {
        setAssessmentDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    } else if (name === "activeToDate") {
      if (!assessmentDetails?.activeToDate) {
        setAssessmentDetails((prev) => {
          return {
            ...prev,
            activeToDate: dayjs(value)?.set("hour", 22)?.set("minute", 0),
          };
        });
      } else {
        setAssessmentDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    } else if (
      name === "easyQuestionsCount" ||
      name === "mediumQuestionsCount" ||
      name === "hardQuestionsCount"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          topicQuestions: "",
        };
      });
      const numericValue = Number(value);
      if (!isNaN(numericValue) && numericValue >= 0) {
        let maxLimit = 0;
        const estimatedCount = calculateAssessmentCounts(
          assessmentDetails?.type?.key,
          assessmentQuestions
        );
        // Determine max limit based on question type
        if (name === "easyQuestionsCount") {
          maxLimit = estimatedCount?.EASY;
        } else if (name === "mediumQuestionsCount") {
          maxLimit = estimatedCount?.MEDIUM;
        } else if (name === "hardQuestionsCount") {
          maxLimit = estimatedCount?.HARD;
        }

        // Check if value exceeds max limit
        if (numericValue > maxLimit) {
          setErrors((prev) => ({
            ...prev,
            topicQuestions: {
              ...(prev.topicQuestions || []),
              [topicId]: {
                ...prev.topicQuestions?.[topicId],
                [name]: `Count cannot be higher than ${maxLimit}`, // Error message for exceeding max
              },
            },
          }));
          return; // Stop further execution if the value exceeds the max limit
        }

        setAssessmentDetails((prev) => {
          const updatedTopicQuestions = [...(prev.topicQuestions || [])]; // Create a copy of topicQuestions array
          const topicIndex = updatedTopicQuestions.findIndex(
            (tq) => tq?.topicId === topicId
          );

          if (topicIndex !== -1) {
            updatedTopicQuestions[topicIndex] = {
              ...updatedTopicQuestions[topicIndex],
              [name]: value,
            };
          } else {
            updatedTopicQuestions.push({
              topicId: topicId,
              [name]: value,
            });
          }

          return {
            ...prev,
            topicQuestions: updatedTopicQuestions,
          };
        });
      } else if (!numericValue) {
        setErrors((prev) => ({
          ...prev,
          topicQuestions: {
            ...(prev.topicQuestions || []),
            [topicId]: {
              ...prev.topicQuestions?.[topicId],
              [name]: "Should contain a number only",
            },
          },
        }));
      }
    } else if (
      [
        "durationInMinutes",
        "markPerHardQuestion",
        "markPerMediumQuestion",
        "markPerEasyQuestion",
      ].includes(name)
    ) {
      if (name !== "durationInMinutes") {
        setErrors((prev) => {
          return {
            ...prev,
            markHierarchy: "",
          };
        });
      }
      errorSetters[name](false);

      if ((value === "" || regex.test(value)) && Number(value) >= 0) {
        setAssessmentDetails((prev) => {
          return {
            ...prev,
            [name]: value === "" ? "" : Number(value),
          };
        });
      } else if (!Number(value)) {
        errorSetters[name](true);
      }
    } else {
      setAssessmentDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };
  const getNavigationPath = () => {
    return viaAssessmentView
      ? viaModule
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}`
        : `/bootcamp/${bootcampId}/assessment/${assessmentId}`
      : viaModule
      ? `/bootcamp/${bootcampId}/module/${moduleId}/assessments`
      : `/bootcamp/${bootcampId}/assessments`;
  };
  const handleAssessmentCancel = () => {
    if (assessmentDetailsChanged) {
      setCancelModalOpen(true);
    } else {
      navigate(getNavigationPath());
    }
  };
  const handleAssessmentSubmit = async () => {
    if (validateFields()) {
      let apiValue;
      const payload = {
        ...assessmentDetails,
        type: assessmentDetails?.type?.key,
        ...(viaModule
          ? { moduleId: assessmentDetails?.moduleId?.id }
          : { bootcampId }),
        ...(assessmentId ? { assessmentId: assessmentId } : {}),
        sessionIds:
          assessmentDetails?.sessionIds?.map((session) => session?.id) || [],
        activeFromDate:
          assessmentDetails?.activeFromDate != null &&
          assessmentDetails?.activeFromDate.$d != "Invalid Date"
            ? dayjs(assessmentDetails?.activeFromDate)?.tz(tz)?.format()
            : null,
        activeToDate:
          assessmentDetails?.activeToDate != null &&
          assessmentDetails?.activeToDate.$d != "Invalid Date"
            ? dayjs(assessmentDetails?.activeToDate)?.tz(tz)?.format()
            : null,
      };
      if (!assessmentId) {
        apiValue = await dispatch(addAssessment(payload));
      } else {
        apiValue = await dispatch(editAssessment(payload));
      }
      if (apiValue?.error?.message === "Rejected") {
        setApiError(true);
      } else {
        navigate(getNavigationPath());
      }
    }
  };

  //////////////
  const isAssessmentActive = assessmentInfo?.active;
  const isEvaluationActive = assessmentInfo?.permission?.isEvaluationActive;

  ///////////////
  useEffect(() => {
    if (assessmentId) {
      dispatch(getAssessmentById(assessmentId));
    }
    dispatch(
      getBootcampByIdOnAssessment({
        bootcampId,
      })
    );
    if (viaModule) {
      dispatch(getSessionsList(moduleId));
      dispatch(getModuleByIdOnAssessment({ bootcampId, moduleId }));
    } else {
      dispatch(getModulesList(bootcampId));
    }

    return () => {
      setAssessmentDetails(initialAssessmentDetails);
      dispatch(clearAssessmentInfo());
      dispatch(clearBootcampInfo());
    };
  }, []);
  useEffect(() => {
    const mapAssessmentDetails = (info) => ({
      title: info?.title,
      type: assessmentTypes?.find((item) => item?.key === info?.type),
      description: info?.description,
      instruction: info?.instruction,
      activeFromDate: dayjs(info?.activeFromDate).isValid()
        ? dayjs(info?.activeFromDate)
        : null,
      activeToDate: dayjs(info?.activeToDate).isValid()
        ? dayjs(info?.activeToDate)
        : null,
      durationInMinutes: info?.durationInMinutes,
      moduleId: info?.module,
      sessionIds: info?.sessions?.results || [],
      markPerEasyQuestion: info?.markPerEasyQuestion,
      markPerMediumQuestion: info?.markPerMediumQuestion,
      markPerHardQuestion: info?.markPerHardQuestion,
      topicQuestions: info?.assessmentTopicQuestions?.results?.map((item) => ({
        topicId: item?.topic?.id,
        name: item?.topic?.name,
        easyQuestionsCount: item?.easyQuestionsCount,
        mediumQuestionsCount: item?.mediumQuestionsCount,
        hardQuestionsCount: item?.hardQuestionsCount,
        assessmentQuestions: item?.topic?.assessmentQuestions,
      })),
    });
    if (assessmentInfo && assessmentTypes) {
      const details = mapAssessmentDetails(assessmentInfo);
      setAssessmentDetails(details);
      setInitialFormDetails(details);
    }
  }, [assessmentInfo, assessmentTypes]);

  /////////////////////////////
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const handleCancel = () => {
    setCancelModalOpen(false);
  };

  const handleNavigation = async () => {
    setCancelModalOpen(false);
    navigate(getNavigationPath());
  };
  /////////////////////////////

  useEffect(() => {
    const updateDetails = () => ({
      ...assessmentDetails,
      sessionIds: sessionsList,
      activeFromDate: dayjs(bootcampEndDate)?.set("hour", 9)?.set("minute", 0),
      activeToDate: dayjs(bootcampEndDate)?.set("hour", 18)?.set("minute", 0),
    });
    if (!viaModule && !assessmentId && sessionsList) {
      setAssessmentDetails(updateDetails());
      setInitialFormDetails(updateDetails());
    }
  }, [bootcampEndDate, sessionsList]);

  useEffect(() => {
    const updateDetails = () => ({
      ...assessmentDetails,
      sessionIds: sessionsList,
      ...(viaModule && {
        moduleId: moduleData,
        activeFromDate: dayjs(moduleData?.endDate)
          ?.set("hour", 9)
          ?.set("minute", 0),
        activeToDate: dayjs(moduleData?.endDate)
          ?.set("hour", 18)
          ?.set("minute", 0),
      }),
    });

    if (sessionsList && !assessmentId && moduleData) {
      setAssessmentDetails((prev) => ({
        ...prev,
        ...updateDetails(),
      }));

      setInitialFormDetails(updateDetails());
    }
  }, [sessionsList, moduleData]);

  /////////////////////// To disable update button if data isn't changed ///////////////////////
  const [initialFormDetails, setInitialFormDetails] =
    useState(assessmentDetails);
  const assessmentDetailsChanged = hasDetailsChanged(
    initialFormDetails,
    assessmentDetails
  );

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: moduleData?.name ? moduleData?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${moduleData?.id}` || "/",
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/module/${moduleData?.id}/assessments`,
        },
        ...(assessmentInfo?.title
          ? [{ label: assessmentInfo?.title }]
          : [
              {
                label: "Assessment-Form",
              },
            ]),
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/assessments`,
        },
        ...(assessmentInfo?.title
          ? [{ label: assessmentInfo?.title }]
          : [
              {
                label: "Assessment-Form",
              },
            ]),
      ];

  // Handler function to delete the chip
  const handleChipDelete = (optionToDelete) => {
    const newSessionIds = assessmentDetails?.sessionIds?.filter(
      (option) => option?.id !== optionToDelete?.id
    );
    handleAssessmentDetailsChange("sessionIds", newSessionIds);
  };

  if (
    modulesListLoading ||
    assessmentInfoLoading ||
    addAssessmentLoader ||
    editAssessmentLoader
  )
    return <Loader />;
  return (
    <Grid container className="main-container">
      <BreadCrumb breadcrumbs={breadcrumbs} />

      <Grid item className="header-container" md={12}>
        <Typography variant="font_21_bold">
          {assessmentId ? "Edit Assessment" : "Create Assessment"}
        </Typography>
      </Grid>

      <Grid item md={12} className="body-container">
        <Grid container className="table-parent-container">
          <Paper elevation={0} className="assessment-paper-container">
            <Grid className="assessment-fields">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "0.75rem",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "20%",
                    marginRight: "1rem",
                  }}
                >
                  <SimpleAutoComplete
                    autoFocus
                    label="Create For"
                    placeholder="Select Option"
                    options={createFor}
                    getOptionLabel={(option) => option?.value}
                    value={viaModule ? createFor[1] : createFor[0]}
                    disabled={true}
                    renderOption={(props, option, value) => {
                      return (
                        <li
                          {...props}
                          key={props?.id}
                          className={
                            option?.value ===
                            assessmentDetails?.createFor?.value
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box lineHeight={1.1}>
                            <Box>{`${option?.value}`}</Box>
                          </Box>
                        </li>
                      );
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "59%",
                    marginRight: "1rem",
                  }}
                >
                  <TextField
                    disabled={isEvaluationActive}
                    size="small"
                    label="Title"
                    color="secondary"
                    fullWidth
                    className="filter-inputs"
                    margin="dense"
                    variant="outlined"
                    value={assessmentDetails?.title}
                    onChange={(e) =>
                      handleAssessmentDetailsChange("title", e?.target?.value)
                    }
                    error={
                      !!errors?.title ||
                      apiError ||
                      sessionSpecialChar ||
                      sessionLengthError
                    }
                    helperText={
                      sessionSpecialChar
                        ? "Should not contain special Characters"
                        : sessionLengthError
                        ? "Maximum 50 characters allowed"
                        : errors?.title
                    }
                    required={true}
                    // disabled={isEditSession && sessionIsConcluded}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "20%",
                  }}
                >
                  <SimpleAutoComplete
                    disabled={isEvaluationActive}
                    label="Type"
                    placeholder="Select Option"
                    options={assessmentTypes}
                    onChange={(event, newValue) =>
                      handleAssessmentDetailsChange("type", newValue)
                    }
                    getOptionLabel={(option) => option?.value || ""}
                    value={assessmentDetails?.type}
                    error={!!errors?.type || apiError}
                    helperText={errors?.type}
                    // disabled={true}
                    required={true}
                    renderOption={(props, option, value) => {
                      return (
                        <li
                          {...props}
                          key={props?.key}
                          className={
                            option?.key === assessmentDetails?.type?.key
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box lineHeight={1.1}>
                            <Box>{`${option?.value}`}</Box>
                          </Box>
                        </li>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid className="assessment-fields">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "0.75rem",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "50%",
                    marginRight: "1rem",
                  }}
                >
                  <TextField
                    multiline
                    minRows={9}
                    label="Description"
                    color="secondary"
                    fullWidth
                    className="filter-inputs"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={assessmentDetails?.description}
                    onChange={(e) =>
                      handleAssessmentDetailsChange(
                        "description",
                        e?.target?.value
                      )
                    }
                    required={true}
                    error={!!errors?.description || apiError}
                    helperText={errors?.description}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "50%",
                    // marginRight: "1rem",
                  }}
                >
                  <Box sx={{ marginBottom: "0.75rem" }}>
                    <Typography
                      color={
                        !!errors.instruction || apiError ? "error" : "offline"
                      }
                      variant="font_14"
                      sx={{ marginBottom: "1rem" }}
                    >
                      Instructions *
                    </Typography>
                  </Box>
                  <CustomToolbar />
                  <ReactQuill
                    className="session-quill"
                    theme="snow"
                    value={assessmentDetails?.instruction}
                    onChange={(value) =>
                      handleAssessmentDetailsChange("instruction", value)
                    }
                    // readOnly={isEditSession && sessionIsConcluded}
                    modules={{
                      toolbar: {
                        container: "#toolbar",
                      },
                    }}
                    placeholder="Enter Text"
                    bounds={".session-quill"}
                  />
                  {!!errors.instruction || apiError ? (
                    <Typography
                      sx={{ paddingLeft: "1rem", marginBottom: "1rem" }}
                      variant="font_12"
                      color="error"
                    >
                      {errors?.instruction}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Grid>

            <Grid className="assessment-fields">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "0.75rem",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "33%",
                    marginRight: "1rem",
                  }}
                >
                  <DateTimePickerComponent
                    disabled={isAssessmentActive || isEvaluationActive}
                    label="Start Date"
                    format="DD/MM/YYYY hh:mm A"
                    onChange={(e) =>
                      handleAssessmentDetailsChange("activeFromDate", e)
                    }
                    value={assessmentDetails?.activeFromDate}
                    views={["year", "day", "hours", "minutes"]}
                    error={!!errors?.activeFromDate || apiError}
                    helperText={errors?.activeFromDate}
                    classList="margin-right-2"
                    required={true}
                    minDateTime={dayjs()}
                    minDate={dayjs().startOf("day").add(1, "day")}
                    shouldDisableDate={(date) => {
                      return shouldDisableWeekends(date) || viaModule
                        ? !eventDateRange(date, assessmentDetails?.moduleId)
                        : !eventDateRange(date, bootcampData);
                    }}
                    closeOnSelect={false}
                    disablePast={true}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "33%",
                    marginRight: "1rem",
                  }}
                >
                  <DateTimePickerComponent
                    label="End Date"
                    format="DD/MM/YYYY hh:mm A"
                    onChange={(e) => {
                      handleAssessmentDetailsChange("activeToDate", e);
                    }}
                    value={assessmentDetails?.activeToDate}
                    required={true}
                    error={!!errors.activeToDate || apiError}
                    helperText={errors?.activeToDate}
                    views={["year", "day", "hours", "minutes"]}
                    minDateTime={dayjs(assessmentDetails?.activeFromDate).add(
                      1,
                      "hour"
                    )}
                    disabled={
                      !assessmentDetails?.activeFromDate || isEvaluationActive
                    }
                    shouldDisableDate={(date) => {
                      return shouldDisableWeekends(date) || viaModule
                        ? !eventDateRange(date, assessmentDetails?.moduleId)
                        : !eventDateRange(date, bootcampData);
                    }}
                    closeOnSelect={false}
                    disablePast={true}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "33%",
                  }}
                >
                  <TextField
                    disabled={isEvaluationActive}
                    size="small"
                    label="Duration"
                    color="secondary"
                    fullWidth
                    className="filter-inputs"
                    margin="dense"
                    variant="outlined"
                    value={assessmentDetails?.durationInMinutes}
                    onChange={(e) =>
                      handleAssessmentDetailsChange(
                        "durationInMinutes",
                        e?.target?.value
                      )
                    }
                    error={
                      !!errors?.durationInMinutes || durationError || apiError
                    }
                    helperText={
                      durationError
                        ? "Should only contain number"
                        : errors?.durationInMinutes
                    }
                    required={true}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid className="assessment-fields">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "0.75rem",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "50%",
                    marginRight: "1rem",
                  }}
                >
                  <SimpleAutoComplete
                    label="Module"
                    placeholder="Select Module"
                    disabled
                    onChange={(event, newValue) =>
                      handleAssessmentDetailsChange("moduleId", newValue)
                    }
                    required={true}
                    error={!!errors?.moduleId || apiError}
                    helperText={errors?.moduleId}
                    getOptionLabel={(option) => option?.name}
                    value={assessmentDetails?.moduleId}
                    renderOption={(props, option, value) => {
                      return (
                        <li
                          {...props}
                          key={props?.id}
                          className={
                            option?.id === assessmentDetails?.moduleId?.id
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box className="flex-options">
                            <Box>{option?.name}</Box>
                          </Box>
                        </li>
                      );
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "0.75rem",
                    flexBasis: "50%",
                  }}
                >
                  <AutoCompleteTagInput
                    disabled={isEvaluationActive}
                    label="Session"
                    required={true}
                    placeholder={
                      assessmentDetails?.sessionIds?.length
                        ? null
                        : "Select Session"
                    }
                    options={sessionsList}
                    selectedValue={assessmentDetails?.sessionIds}
                    getOptionLabel={(option) => option?.name}
                    filterOptions={(options, params) => {
                      // <<<--- inject the Select All option
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      if (sessionsList?.length === 0) {
                        return [{ name: "No options", all: false }];
                      }
                      return [{ name: "Select All", all: true }, ...filtered];
                    }}
                    onChange={(event, newValue) => {
                      const idCounts = newValue.reduce((counts, { id }) => {
                        counts[id] = (counts[id] || 0) + 1;
                        return counts;
                      }, {});
                      const uniqueNewValue = newValue.filter(({ id }) => {
                        return idCounts[id] === 1;
                      });
                      if (newValue?.find((option) => option.all)) {
                        return handleAssessmentDetailsChange(
                          "sessionIds",
                          assessmentDetails?.sessionIds?.length ===
                            sessionsList?.length
                            ? []
                            : sessionsList
                        );
                      }
                      if (assessmentId && event?.keyCode === 8) {
                        const lastItem =
                          assessmentDetails?.sessionIds[
                            assessmentDetails?.sessionIds?.length - 1
                          ];
                        if (disabledSessionsId?.includes(lastItem?.id)) {
                          return;
                        }
                      }
                      handleAssessmentDetailsChange(
                        "sessionIds",
                        uniqueNewValue
                      );
                    }}
                    renderOption={(props, option, value) => {
                      // Check if option is "No options" and render accordingly
                      if (option.name === "No options") {
                        return (
                          <Box className="auto-complete-no-options">
                            <Box>{option?.name}</Box>
                          </Box>
                        );
                      }
                      const optionChecked =
                        (option?.all &&
                          assessmentDetails?.sessionIds?.length ===
                            sessionsList?.length) ||
                        assessmentDetails?.sessionIds?.find(
                          (e) => e?.id === option?.id
                        ) != null;
                      const optionsDisabled =
                        assessmentId &&
                        disabledSessionsId?.includes(option?.id);

                      return (
                        <li
                          {...props}
                          key={props?.id}
                          className={
                            optionsDisabled
                              ? "multi-tag-edit-list-selected-disabled"
                              : optionChecked
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box className="multi-tag-input-list-padding">
                            <Checkbox
                              className="auto-complete-checkbox-list"
                              size="small"
                              checked={optionChecked}
                              color="secondary"
                            />
                            {option?.name}
                          </Box>
                        </li>
                      );
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const showCrossIcon = !(
                          assessmentId &&
                          disabledSessionsId?.includes(option?.id)
                        );
                        return (
                          <Chip
                            {...getTagProps(index)}
                            key={index}
                            label={option?.name}
                            onDelete={
                              showCrossIcon || !assessmentId
                                ? () => handleChipDelete(option)
                                : undefined
                            }
                          />
                        );
                      })
                    }
                    getOptionDisabled={(option) =>
                      assessmentId && disabledSessionsId?.includes(option?.id)
                    }
                    disableClearable={
                      assessmentId &&
                      allValuesDisabled(
                        assessmentDetails?.sessionIds,
                        disabledSessionsId
                      )
                    }
                    error={!!errors?.sessionIds || apiError}
                    helperText={errors?.sessionIds}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid className="assessment-fields">
              <Box
                sx={{
                  marginBottom: "0.75rem",
                }}
              >
                <Box sx={{ marginBottom: "0.75rem" }}>
                  <Typography variant="font_14">Question Pool</Typography>
                </Box>
                <QuestionPoolTable
                  selectedSessions={assessmentDetails?.sessionIds || []}
                  handleAssessmentDetailsChange={handleAssessmentDetailsChange}
                  assessmentTopicQuestions={
                    assessmentId ? assessmentDetails?.topicQuestions : false
                  }
                  errors={errors}
                  apiError={apiError}
                  assessmentDetails={assessmentDetails}
                  setAssessmentDetails={setAssessmentDetails}
                  isEvaluationActive={isEvaluationActive}
                />
              </Box>
            </Grid>

            <Grid className="assessment-fields">
              <Box
                sx={{
                  marginBottom: "0.75rem",
                }}
              >
                <Box sx={{ marginBottom: "0.75rem" }}>
                  <Typography variant="font_14">
                    Score Per Question Type
                  </Typography>
                </Box>
                <QuestionTypeTable
                  selectedSessions={assessmentDetails?.sessionIds}
                  handleAssessmentDetailsChange={handleAssessmentDetailsChange}
                  errors={errors}
                  assessmentDetails={assessmentDetails}
                  apiError={apiError}
                  easyError={easyError}
                  mediumError={mediumError}
                  HardError={HardError}
                  isEvaluationActive={isEvaluationActive}
                />
              </Box>
            </Grid>
            <Grid
              sx={{
                padding: "1.875rem 0 1.625rem",
                display: "flex",
                width: "26.265rem",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleAssessmentCancel}
                className="block-button margin-right-2"
              >
                <Typography variant="outlineBtnLabel">Cancel</Typography>
              </Button>
              <Button
                onClick={handleAssessmentSubmit}
                variant="contained"
                color="secondary"
                className="block-button"
                disabled={!assessmentDetailsChanged}
              >
                <Typography variant="outlineBtnLabel">
                  {assessmentId ? "Update" : "Save"}
                </Typography>
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <DeleteModal
        open={cancelModalOpen}
        title="Are you sure? Canceling will discard all the data you've entered"
        handleClose={handleCancel}
        handleDelete={handleNavigation}
        buttonText="Yes"
      />
    </Grid>
  );
};

export default CreateAssessment;
