import React, { useEffect, useState } from "react";
import "./assessmentQuestions.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

import { useDispatch, useSelector } from "react-redux";
import {
  clearAssessmentQuestionsData,
  deleteAssessmentQuestion,
  deleteOption,
  getTopicById,
  setAssessmentQuestionsData,
  toggleQuestionAssessment,
} from "../../store/assessmentQuestions";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import {
  ROLE_TYPES,
  checkSpecialChar,
  font_13_icon,
  regex,
} from "../../utils/utils";
import { useParams } from "react-router-dom/dist";
import AssessmentQuestionsCard from "../../components/AssessmentQuestionsCard/AssessmentQuestionsCard";
import { filter_active_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import AssessmentFilter from "./AssessmentFilter";
import AddAssessmentQuestion from "./AddAssessmentQuestion";
import AssessmentOptionModal from "../../components/AssessmentOptionModal/assessmentOptionModal";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

const AssessmentQuestions = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isAdmin = ROLE_TYPES.isAdmin(roles);
  const assessmentQuestions = useSelector(
    (state) => state?.assessmentQuestions
  );
  const singleTopicData = assessmentQuestions?.singleTopicData?.data;
  const topicDataLoading = assessmentQuestions?.topicDataLoader;
  const {
    canViewCreateAssessmentQuestion,
    canCreateAssessmentQuestion,
    createAssessmentQuestionMessage,
  } = singleTopicData?.permission || {};
  const assessmentQuestionsData = assessmentQuestions?.assessmentQuestionsData;
  const loading = assessmentQuestions?.assessmentQuestionsLoader;
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [filterActivated, setFilterActivated] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getTopicById({ id: params.topicId }));
      await dispatch(setAssessmentQuestionsData({ topicId: params.topicId }));
      setIsLoading(false);
    };
    fetchData();

    return () => {
      dispatch(clearAssessmentQuestionsData());
    };
  }, [dispatch]);

  useEffect(() => {
    setIsError(assessmentQuestions?.error);
  }, [assessmentQuestions.error, isError]);

  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [question, setQuestion] = useState(null);
  const [optionId, setOptionId] = useState(null);
  const [option, setOption] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [isCloneQuestion, setIsCloneQuestion] = useState(false);
  const [addQuestionOpen, setAddQuestionOpen] = useState(false);
  const handleAddQuestionModalOpen = async (bootcamp) => {
    // setModuleSpeChar(false);
    // setBootcampForModuleModal(bootcamp);
    // await setBootcampModuleDetails((prev) => {
    //   return {
    //     ...prev,
    //     // id: expandBootcampId,
    //     bootcampIds: [bootcamp],
    //     // startDate: moduleStartDate,
    //   };
    // });
    setAddQuestionOpen(true);
  };
  // //----------------

  const handleOptionModalOpen = (id) => {
    setOptionModalOpen(true);
    setQuestionId(id);
  };
  const optionEditModal = (optionInfo) => {
    setOptionModalOpen(true);
    setOptionId(optionInfo?.id);
    setOption(optionInfo);
  };

  const handleEditQuestionModalOpen = (event, id, question) => {
    event.stopPropagation();
    setIsEditQuestion(true);
    setAddQuestionOpen(true);
    setQuestionId(id);
    setQuestion(question);
  };

  const cloneQuestionModalOpen = (event, question) => {
    event.stopPropagation();
    setIsCloneQuestion(true);
    setAddQuestionOpen(true);
    setQuestion(question);
  };

  const toggleQuestionModalOpen = async (event, status, questionId) => {
    event.stopPropagation();
    await dispatch(toggleQuestionAssessment({ questionId, active: status }));
    await dispatch(setAssessmentQuestionsData({ topicId: params.topicId }));
  };

  const handleDeleteModalOpen = (event, question, option = null) => {
    event.stopPropagation();
    setDeleteModalOpen(true);
    setQuestionId(question.id);
    setOptionId(option ? option.id : null);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    if (optionId) {
      await dispatch(deleteOption(optionId));
    } else {
      await dispatch(deleteAssessmentQuestion(questionId));
    }
    await dispatch(setAssessmentQuestionsData({ topicId: params.topicId }));
    setDeleteModalOpen(false);
  };
  //-------------------------------------------------------------------

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  //------------------------------ Breadcrumbs ------------------------------//

  const breadcrumbs = [
    { label: "Topics", url: "/topic" },
    { label: "Assessment Questions" },
  ];

  if ((loading && isLoading) || topicDataLoading) return <Loader />;

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Assessment Questions</Typography>
          <Box className="header-right-icons">
            {canViewCreateAssessmentQuestion && (
              <BootstrapTooltip
                title={
                  !canCreateAssessmentQuestion
                    ? createAssessmentQuestionMessage
                    : null
                }
              >
                <Box>
                  <Button
                    className="action-head-btn"
                    variant="outlined"
                    color="secondary"
                    onClick={handleAddQuestionModalOpen}
                    disabled={!canCreateAssessmentQuestion}
                  >
                    <Typography variant="outlineBtnLabel">
                      Add Question
                    </Typography>
                  </Button>
                </Box>
              </BootstrapTooltip>
            )}
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                tooltip="Add Filters"
                width={font_13_icon}
                height={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Paper
            elevation={0}
            container
            className="assessment-questions-parent-container"
          >
            {!loading && !assessmentQuestionsData?.data?.results?.length ? (
              <Grid container className="no-data-container">
                <NoDataAvailable
                  imgHeight={4}
                  imgWidth={4.5}
                  message="No Questions Exist"
                />
              </Grid>
            ) : (
              assessmentQuestionsData?.data?.results?.map((e, index) => (
                <AssessmentQuestionsCard
                  index={index}
                  key={e.id}
                  handleOptionModalOpen={handleOptionModalOpen}
                  questionEditModal={(event, id, question) =>
                    handleEditQuestionModalOpen(event, id, question)
                  }
                  cloneQuestionModalOpen={(event, question) =>
                    cloneQuestionModalOpen(event, question)
                  }
                  toggleQuestionModalOpen={(event, status, questionId) =>
                    toggleQuestionModalOpen(event, status, questionId)
                  }
                  optionEditModal={optionEditModal}
                  deleteModalOpenQuestion={(event) =>
                    handleDeleteModalOpen(event, e)
                  }
                  deleteModalOpenOption={(event, option) =>
                    handleDeleteModalOpen(event, e, option)
                  }
                  question={e}
                  // questionType={e?.type}
                  // questionTitle={e?.title}
                  // questionDescription={e?.description}
                  // questionOptions={e?.questionOptions}
                  id={e?.id}
                  isDeleted={e?.deleted}
                  isAdmin={isAdmin}
                />
              ))
            )}
          </Paper>
        </Grid>
        <AssessmentOptionModal
          optionModalOpen={optionModalOpen}
          setOptionModalOpen={setOptionModalOpen}
          questionId={questionId}
          optionId={optionId}
          option={option}
          setOptionId={setOptionId}
        />
        <DeleteModal
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
        <AssessmentFilter
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          filterActivated={filterActivated}
          setFilterActivated={setFilterActivated}
          filterCount={filterCount}
          setFilterCount={setFilterCount}
        />
        <AddAssessmentQuestion
          isEditQuestion={isEditQuestion}
          setIsEditQuestion={setIsEditQuestion}
          isCloneQuestion={isCloneQuestion}
          setIsCloneQuestion={setIsCloneQuestion}
          addQuestionOpen={addQuestionOpen}
          setAddQuestionOpen={setAddQuestionOpen}
          question={question}
          setQuestion={setQuestion}
        />
      </Grid>
    </>
  );
};

export default AssessmentQuestions;
