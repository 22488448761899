import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getAssessmentEvaluationByIdApi(id) {
  const options = {
    method: "GET",
    url: `${apiVersion}/assessmentEvaluation/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function getBootcampByIdApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}