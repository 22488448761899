import React, { useEffect, useState } from "react";
import "./assessmentOptionModal.scss";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { hasDetailsChanged, hasTrimmedDetailsChanged } from "../../utils/utils";
import {
  addOption,
  setAssessmentQuestionsData,
  updateOption,
} from "../../store/assessmentQuestions";
import { useParams } from "react-router-dom";

const AssessmentOptionModal = ({
  questionId,
  optionModalOpen,
  setOptionModalOpen,
  optionId,
  setOptionId,
  isEditLocation,
  locationSpecialChar,
  locationNameLengthError,
  option,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const loader = useSelector(
    (state) => state?.locationAndRoom.addEditLocationLoading
  );
  const initialInputDetails = {
    optionId: "",
    questionId: "",
    optionText: "",
    correct: false,
  };
  const [optionDetails, setOptionDetails] = useState(initialInputDetails);

  //   const [initialOptionDetails, setInitialOptionDetails] =
  //     useState(optionDetails);

  useEffect(() => {
    if (optionId) {
      setOptionDetails((prev) => ({
        ...prev,
        optionId: option?.id,
        optionText: option?.optionText,
        correct: option?.correct,
      }));
    }
  }, [optionModalOpen]);

  const initialOptionErrorState = {
    optionText: false,
  };
  const [optionError, setOptionError] = useState(false);
  const [optionErrors, setOptionErrors] = useState(initialOptionErrorState);
  //   const handleOptionDetailsChanged =
  //     initialOptionDetails?.optionText?.trim() !==
  //     optionDetails?.optionText?.trim();

  const validateOptionFields = (sessionDetails) => {
    const { optionText } = sessionDetails;
    const newErrors = {
      name: optionText?.trim() ? "" : "Option is required",
    };
    setOptionErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleOptionModalClose = () => {
    setOptionModalOpen(false);
    setOptionDetails(initialInputDetails);
    // setOptionDetails(initialOptionDetails);
    // setLocationNameLengthError(false);
    setOptionError(false);
    setOptionErrors(initialOptionErrorState);
    // setOption(null);
    setOptionId(null);
  };
  const handleOptionSubmit = async () => {
    const data = {
      optionId,
      ...(optionId ? {} : { questionId }),
      optionText: optionDetails?.optionText.trim(),
      correct: optionDetails?.correct,
    };

    let apiValue;
    if (validateOptionFields(data) && !locationSpecialChar) {
      if (!optionId) {
        apiValue = await dispatch(addOption(data));
      } else {
        apiValue = await dispatch(updateOption(data));
      }
      if (apiValue?.error?.message === "Rejected") {
        setOptionError(true);
      } else {
        await dispatch(setAssessmentQuestionsData({ topicId: params.topicId }));
        handleOptionModalClose();
      }
    }
  };
  const handleOptionDetailsChange = (name, value) => {
    setOptionErrors(initialOptionErrorState);
    setOptionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={optionModalOpen}
        onClose={handleOptionModalClose}
        PaperProps={{
          style: {
            width: "22.125rem",
            zIndex: 1300,
            borderRadius: "0.6rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="create-option-modal-title">
          <Typography variant="font_16_bold">
            {optionId ? "Edit Option" : "Create Option"}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "0.5rem 2rem 0" }}>
          <TextField
            multiline
            minRows={3}
            //   disabled={isAddCommentDisabled}
            autoFocus
            id="option"
            shrink={false}
            size="small"
            label="Option"
            type="text"
            fullWidth
            margin="dense"
            variant="outlined"
            value={optionDetails?.optionText}
            onChange={(e) =>
              handleOptionDetailsChange("optionText", e.target.value)
            }
            color="secondary"
            error={
              !!optionErrors.name ||
              optionError ||
              locationSpecialChar ||
              locationNameLengthError
            }
            helperText={optionErrors.name}
            className="create-option-input"
            required={true}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                size="small"
                checked={optionDetails?.correct}
                onChange={(e) =>
                  handleOptionDetailsChange("correct", e.target.checked)
                }
              />
            }
            label="Is Correct"
          />
        </DialogContent>
        <DialogActions sx={{ padding: "1rem 2rem 1.2rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleOptionModalClose}
            className="create-option-btn"
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            onClick={handleOptionSubmit}
            variant="contained"
            color="secondary"
            className="create-option-btn"
            disabled={
              isEditLocation
              // && !handleOptionDetailsChanged
            }
          >
            <Typography variant="font_12_bold_700">
              {isEditLocation ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssessmentOptionModal;
