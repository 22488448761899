import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import "./questionPoolTable.scss";
import dayjs from "dayjs";
import { useEffect } from "react";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

const QuestionPoolTable = ({
  handleAssessmentDetailsChange,
  selectedSessions,
  assessmentTopicQuestions,
  errors,
  apiError = false,
  assessmentDetails,
  setAssessmentDetails,
  viewAssessment,
  isEvaluationActive,
}) => {
  const topicList = selectedSessions
    ?.map((session) => session?.topic)
    ?.filter(
      (topic, index, self) =>
        topic && index === self.findIndex((t) => t?.id === topic.id) // Filter unique topics by id
    );
  const topicListSelectData = topicList?.map((item, index) => {
    return {
      topicId: item?.id,
      name: item?.name,
      easyQuestionsCount:
        assessmentDetails?.topicQuestions?.[index]?.easyQuestionsCount,
      mediumQuestionsCount:
        assessmentDetails?.topicQuestions?.[index]?.mediumQuestionsCount,
      hardQuestionsCount:
        assessmentDetails?.topicQuestions?.[index]?.hardQuestionsCount,
      assessmentQuestions: item?.assessmentQuestions,
    };
  });

  useEffect(() => {
    if (topicListSelectData && selectedSessions?.length) {
      setAssessmentDetails((prev) => {
        return {
          ...prev,
          topicQuestions: topicListSelectData,
        };
      });
    }
  }, [selectedSessions]);

  return (
    <>
      <TableContainer className="table-container question-pool-table-container">
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ minWidth: "100%", marginBottom: "0.75rem" }}
        >
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              <TableCell align="left">
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "20rem" }}
                >
                  <Box className="table-sort-head">Topic</Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Easy</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography sx={{ minWidth: "10rem" }} variant="font_13_bold">
                  Medium
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "10rem" }}
                >
                  Hard
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(viewAssessment
              ? assessmentTopicQuestions
              : topicListSelectData
            )?.map((topic, index) => {
              const topicName = topic?.name || topic?.topic?.name;
              const topicId = topic?.topicId || topic?.topic?.topicId;
              const easyQuestionsCount =
                assessmentDetails?.topicQuestions?.[index]?.easyQuestionsCount;
              const mediumQuestionsCount =
                assessmentDetails?.topicQuestions?.[index]
                  ?.mediumQuestionsCount;
              const hardQuestionsCount =
                assessmentDetails?.topicQuestions?.[index]?.hardQuestionsCount;
              const assessmentQuestions = topic?.assessmentQuestions;

              return (
                <TableRow className="table-body-row" key={index}>
                  <TableCell className="padding-left-1">
                    <Typography variant="font_12">{topicName}</Typography>
                    {!viewAssessment &&
                      !!errors.topicQuestions &&
                      errors?.topicQuestions[index] && (
                        <>
                          <br />
                          <Typography
                            color={
                              errors.topicQuestions[index] || apiError
                                ? "error"
                                : "offline"
                            }
                            variant="font_12"
                            sx={{ marginBottom: "1rem" }}
                          >
                            {errors?.topicQuestions[index]}
                          </Typography>
                        </>
                      )}
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <TextField
                      disabled={viewAssessment || isEvaluationActive}
                      size="small"
                      color="secondary"
                      sx={{ maxWidth: "12.25rem" }}
                      className="filter-inputs"
                      margin="dense"
                      variant="outlined"
                      value={easyQuestionsCount}
                      onChange={(e) =>
                        handleAssessmentDetailsChange(
                          "easyQuestionsCount",
                          +e?.target?.value,
                          topicId,
                          assessmentQuestions
                        )
                      }
                      error={
                        apiError ||
                        errors?.topicQuestions?.[topicId]?.easyQuestionsCount
                      }
                      helperText={
                        errors?.topicQuestions?.[topicId]?.easyQuestionsCount
                      }
                    />
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <TextField
                      disabled={viewAssessment || isEvaluationActive}
                      size="small"
                      color="secondary"
                      sx={{ maxWidth: "12.25rem" }}
                      className="filter-inputs"
                      margin="dense"
                      variant="outlined"
                      value={mediumQuestionsCount}
                      onChange={(e) =>
                        handleAssessmentDetailsChange(
                          "mediumQuestionsCount",
                          +e?.target?.value,
                          topicId,
                          assessmentQuestions
                        )
                      }
                      error={
                        apiError ||
                        errors?.topicQuestions?.[topicId]?.mediumQuestionsCount
                      }
                      helperText={
                        errors?.topicQuestions?.[topicId]?.mediumQuestionsCount
                      }
                    />
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <TextField
                      disabled={viewAssessment || isEvaluationActive}
                      size="small"
                      color="secondary"
                      sx={{ maxWidth: "12.25rem" }}
                      className="filter-inputs"
                      margin="dense"
                      variant="outlined"
                      value={hardQuestionsCount}
                      onChange={(e) =>
                        handleAssessmentDetailsChange(
                          "hardQuestionsCount",
                          +e?.target?.value,
                          topicId,
                          assessmentQuestions
                        )
                      }
                      error={
                        apiError ||
                        errors?.topicQuestions?.[topicId]?.hardQuestionsCount
                      }
                      helperText={
                        errors?.topicQuestions?.[topicId]?.hardQuestionsCount
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuestionPoolTable;
