import { Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { evaluationStatusChip } from "../../utils/utils";

const ViewAssessmentEvaluationPanel = ({ assessmentInfo }) => {
  const assessment = assessmentInfo?.assessment;
  const attendee =
    assessmentInfo?.bootcampAttendee?.attendee ||
    assessmentInfo?.moduleAttendee?.attendee?.attendee;
  const leadInterviewer = assessmentInfo?.leadInterviewer?.fullName;
  const coInterviewer = assessmentInfo?.coInterviewer?.fullName;
  const percentage = assessmentInfo?.percentage;
  const status = evaluationStatusChip(assessmentInfo?.status);

  /////////////////////////////////////////////////////////////

  return (
    <Box className="margin-bottom-5">
      <Grid container className="assessment-panel-container">
        <Grid item md={7} className="assessment-left-right-info">
          <Grid container item md={12} className="assessment-left-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Assessment Title
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} className="assessment-panel-info-box2">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">{assessment?.title}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} className="assessment-left-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Attendee Name
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">{attendee?.fullName}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} className="assessment-left-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Interviewers (Lead, Co)
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} className="assessment-panel-info-box6">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">
                  {(leadInterviewer, coInterviewer)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} className="assessment-left-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Score
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} className="assessment-panel-info-box6">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">Attendees from Module</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} className="assessment-panel-right-info">
          <Grid container item md={12} className="assessment-right-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Percentage
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} className="assessment-panel-info-box2">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">{percentage}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} className="assessment-right-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Status
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12">
                  <Chip
                    className="same-size-chip"
                    label={status?.name}
                    variant={status?.theme}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} className="assessment-right-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Submitted On
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} className="assessment-panel-info-box6">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">
                  {assessmentInfo?.endDate}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} className="assessment-right-rows">
            <Grid item md={3}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Attendees
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} className="assessment-panel-info-box6">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_14">Attendees from Module</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={7}
          className="assessment-panel-info"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Grid item md={3}>
            <Box className="assessment-panel-info-box">
              <Typography variant="font_12" className="grey-text">
                Overall Feedback
              </Typography>
            </Box>
          </Grid>
          <Grid item md={9} className="assessment-panel-info-box6">
            <Box className="assessment-panel-info-box">
              <Typography variant="font_14">
                {assessmentInfo?.interviewerFeedback}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewAssessmentEvaluationPanel;
